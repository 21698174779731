import * as React from 'react'

import { Box, CardContent } from '@material-ui/core'
import {
  Card,
  CardFooter,
  TextFooter,
  CardHeader,
  CardNotify,
  TextContent,
  Title,
} from './styles'

interface Props {
  elevation?: number
  variant?: 'outlined' | 'elevation'
  selected?: boolean
  title?: string
  textContent?: string | number
  componentContent?: any[]
  textFooter?: string | number
  alignCard?: 'center' | 'left' | 'right'
  square?: boolean
  hover?: boolean
  colorFull?: boolean
  cursor?: boolean
  cardType?: 'primary' | 'secondary' | string
  onClick?: () => void
  headerAction?: React.ReactNode
}

const CardInfo: React.FC<Props> = ({
  elevation = 0,
  title,
  textContent,
  componentContent,
  textFooter,
  alignCard = 'center',
  variant = 'elevation',
  selected,
  square,
  hover,
  cursor,
  cardType,
  colorFull,
  headerAction,
  onClick,
  ...rest
}: Props) => {
  return (
    <Card
      {...rest}
      elevation={elevation}
      selected={selected}
      variant={variant}
      data-testid="card-info"
      onClick={onClick}
      square={square}
      hover={hover}
      cardtype={cardType}
      alignCard={alignCard}
      colorFull={colorFull}
      infoCursor={cursor}
    >
      {!!title && (
        <CardHeader>
          <Title variant="subtitle1">{title}</Title>
        </CardHeader>
      )}
      <CardContent>
        {!!headerAction && <CardNotify>{headerAction}</CardNotify>}
        {!!textContent && <TextContent variant="h3">{textContent}</TextContent>}
        {componentContent && componentContent?.length > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {componentContent}
          </Box>
        )}
        {componentContent && componentContent?.length === 1 && componentContent}
      </CardContent>
      {!!textFooter && (
        <CardFooter>
          <TextFooter variant="subtitle2">{textFooter}</TextFooter>
        </CardFooter>
      )}
    </Card>
  )
}

CardInfo.defaultProps = {
  elevation: 0,
  title: '',
  textContent: '',
  componentContent: undefined,
  textFooter: '',
  variant: 'elevation',
  selected: false,
  alignCard: 'center',
  cardType: 'primary',
  square: true,
  hover: false,
  cursor: false,
  colorFull: false,
  headerAction: null,
  onClick: (): void => {},
}

export default CardInfo
