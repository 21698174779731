import React, { useCallback } from 'react'
import { useDropzone, DropzoneProps, FileRejection } from 'react-dropzone'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import { DropUpload, LargeDropUpload } from './styles'

interface UploadDragDropProps extends DropzoneProps {
  uploadDisabled?: boolean
  multiple?: boolean
  accept?: string
  onDrop: (acceptedFiles: File[]) => void
  onDropRejected?: (fileRejection: FileRejection[]) => void
  uploading?: boolean
  linkText?: string
  maxSize?: number
  text?: string
  size?: 'small' | 'large'
}

const UploadDragDrop: React.FC<UploadDragDropProps> = ({
  uploadDisabled,
  multiple,
  accept,
  onDrop,
  uploading,
  linkText,
  text,
  size,
  maxSize,
  onDropRejected,
}: UploadDragDropProps) => {
  const handleDrop = useCallback(
    (acceptedFiles: any): void => {
      onDrop(acceptedFiles)
    },
    [onDrop]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept,
    disabled: uploadDisabled,
    multiple,
    maxSize,
    onDropRejected,
  })

  return (
    <div>
      {size === 'small' ? (
        <DropUpload
          size={size}
          isDragActive={isDragActive}
          {...getRootProps()}
          disabled={uploadDisabled}
        >
          <input {...getInputProps()} type="file" />
          <div className="upload-placeholder">
            <Box mr={2} display="flex" alignItems="center">
              {uploading ? (
                <CircularProgress size={16} />
              ) : (
                <CloudUploadIcon className="upload-icon" />
              )}
            </Box>
            <strong>{linkText} </strong> {text}
          </div>
        </DropUpload>
      ) : (
        <LargeDropUpload
          size={size}
          isDragActive={isDragActive}
          {...getRootProps()}
          disabled={uploadDisabled}
        >
          <input {...getInputProps()} type="file" />
          <div className="upload-placeholder">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box display="flex" flexDirection="row" alignItems="center">
                {uploading ? (
                  <CircularProgress size={16} />
                ) : (
                  <CloudUploadOutlinedIcon
                    fontSize="large"
                    className="upload-icon"
                  />
                )}
              </Box>
              <Box
                mt={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <strong>{linkText} </strong>
                <Box mt={3} className="upload-text">
                  {text}
                </Box>
              </Box>
            </Box>
          </div>
        </LargeDropUpload>
      )}
    </div>
  )
}

UploadDragDrop.defaultProps = {
  size: 'large',
  linkText: 'Browse',
  text: 'or Drop files here to upload',
  uploadDisabled: false,
  multiple: false,
  uploading: false,
  maxSize: Infinity,
  onDropRejected: (): void => {},
  accept:
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
}

export default UploadDragDrop
