import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import { BusinessContext } from 'src/context/BusinessContext'
import { GetAccessToken } from 'src/api/axios/helper'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { formatDateAndTimeLocal, getLocaleDateString } from 'src/utils/date'
import {
  CancelRounded,
  CheckCircle,
  ErrorRounded,
  MoreVert,
} from '@material-ui/icons'
import { AbilityContext } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'
import { PermissionCodeAccess } from 'src/utils/constants'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'
import { ICertificationPeriod } from 'src/api/models'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { getAcronym } from 'src/utils/common'
import ReviewQuestion from 'src/components/ReviewQuestion'
import AcceptsRejectFileModal from './AcceptRejectFileModal'
import DeleteFileModal from './DeleteFileModal'
import DocumentTransferDialog from './TransferFileDialog'

interface DocumentFilesProps {
  documentRequestId: number
  documentData: any
  isRegistration: boolean
  approval: boolean
}

const DocumentFiles: React.FC<DocumentFilesProps> = ({
  documentRequestId,
  documentData,
  isRegistration,
  approval,
}: DocumentFilesProps) => {
  const userToken = GetAccessToken()
  const businessContext = useContext<any>(BusinessContext)
  const certificationPeriodSelected = useContext<ICertificationPeriod>(
    CertificationPeriodContext
  )
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const ability = useContext(AbilityContext)
  const notistackSuccess = notistackOptions('success')
  const [filesToDownload, setFilesToDownload] = useState<any[]>([])
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [openApproveDialog, setOpenApproveDialog] = useState<boolean>(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [openTransferDialog, setOpenTransferDialog] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = React.useState<any>()
  const [filesData, setFiles] = React.useState<any[]>([])
  const [action, setAction] = React.useState<string>('')
  const { enqueueSnackbar } = useSnackbar()
  const [openQuestionDialog, setOpenQuestionDialog] = useState<boolean>(false)
  const readOnly = ability.can(
    PermissionCodeAccess.ComplianceManagementSystem_Basic_ViewAccess,
    'any'
  )

  const canApproveReject =
    isInternal &&
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_Internal,
      'any'
    )

  const canDeleteAcceptedFile =
    isInternal &&
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_DeleteDocument,
      'any'
    )

  const open = Boolean(anchorEl)
  const handleClick = (event: any, file: any) => {
    setAnchorEl(event.currentTarget)
    setSelectedFile(file)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const getFileUrl = useQuery({
    enabled: false,
    queryKey: ['getFileUrl', selectedFile, businessContext],
    queryFn: async () => {
      const name = approval
        ? documentData[selectedIndex].name
        : documentData.name
      const aliasName = approval
        ? documentData[selectedIndex].aliasName
        : documentData.aliasName

      return axios({
        method: 'get',
        url: `${BACKEND_URL}/businessUpload/GetUrlFile`,
        headers: {
          Authorization: userToken,
        },
        params: {
          fileNameOnDownload: `${businessContext.name}_${
            aliasName ?? getAcronym(name)
          }_${filesData[selectedIndex].originalFileName}`,
          originalFileName: filesData[selectedIndex].originalFileName,
          blobUri: filesData[selectedIndex].blobUri,
        },
      }).then((result: any) => {
        return result.data
      })
    },
    onSuccess: (data) => {
      window.location.href = data
    },
  })

  const handleDownloadFiles = () => {
    if (filesToDownload.length > 1) {
      enqueueSnackbar(
        'Creating zip file. This process may take some time.',
        notistackSuccess
      )
    }
    getFilesDownload()
  }

  const handleDownload = async () => {
    await getFileUrl.refetch()
  }

  const {
    isLoading,
    refetch: getFilesDownload,
    isRefetching,
  } = useQuery({
    queryKey: ['getFilesDownload', JSON.stringify(filesToDownload)],
    queryFn: async () => {
      const name = approval
        ? documentData[selectedIndex].name
        : documentData.name
      const aliasName = approval
        ? documentData[selectedIndex].aliasName
        : documentData.aliasName
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/BusinessUpload/GetFilesDownload`,
        params: {
          files: JSON.stringify(filesToDownload),
          fileSectionName:
            filesToDownload.length === 1
              ? `${businessContext.name}_${aliasName ?? getAcronym(name)}_${
                  filesData.find((t: any) => t.blobUri === filesToDownload[0])
                    .originalFileName
                }`
              : aliasName ?? getAcronym(name),
          approval,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
    onSuccess: (data) => {
      window.location.href = data
    },
    enabled: false,
  })

  const getFilesUploadedQuery = useQuery({
    queryKey: [
      'getfilesUploaded',
      documentRequestId,
      businessContext.id,
      certificationPeriodSelected.certificationDate,
    ],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/businessupload`,
        params: {
          businessUploadRequestId: documentRequestId,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((response) => {
        setFiles(response.data)
      })
    },
    refetchInterval: 30000,
    enabled: isRegistration,
    cacheTime: 0,
  })

  const getRepoFilesUploadedQuery = useQuery({
    queryKey: ['getRepoFilesUploaded', documentRequestId, businessContext.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/repository/GetDocumentsById`,
        params: {
          id: documentRequestId,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((response) => {
        setFiles(response.data)
      })
    },
    refetchInterval: 30000,
    enabled: approval,
    cacheTime: 0,
  })

  const GetRepoFilesQuery = useQuery({
    queryKey: [
      'GetRepoFiles',
      documentRequestId,
      certificationPeriodSelected.certificationDate,
    ],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/Repository/GetRepoFiles`,
        params: {
          businessUploadRequestId: documentRequestId,
          certificationPeriod: certificationPeriodSelected.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((response) => {
        setFiles(response.data)
      })
    },
    refetchInterval: 30000,
    enabled: !isRegistration && !approval,
    cacheTime: 0,
  })

  const getStatusIcon = (status: string) => {
    if (status) {
      if (status === 'Rejected')
        return (
          <Tooltip title="Rejected">
            <CancelRounded style={{ color: 'red' }} />
          </Tooltip>
        )
      if (status === 'Accepted')
        return (
          <Tooltip title="Accepted">
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )
    }

    return (
      <Tooltip title={status}>
        <ErrorRounded style={{ color: '#FFA500' }} />
      </Tooltip>
    )
  }

  const handleFilterChange = (e: any) => {
    if (filesToDownload && filesToDownload.includes(e)) {
      setFilesToDownload(filesToDownload.filter((f) => f !== e))
    } else {
      setFilesToDownload(filesToDownload.concat(e))
    }
  }

  const handleListItemClick = (index: number) => setSelectedIndex(index)

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box
          borderRight="1px solid #e8e8e8"
          overflow="auto"
          width={400}
          height={450}
        >
          {filesData && filesData?.length > 0 ? (
            <List>
              {filesData.map((file: any, index: number) => {
                return (
                  <ListItem
                    button
                    selected={selectedIndex === index}
                    key={file.id}
                    onClick={() => handleListItemClick(index)}
                  >
                    <ListItem style={{ padding: 5 }}>
                      <Checkbox
                        onClick={() => handleFilterChange(file.blobUri)}
                        id={`checkbox_${file.id}`}
                        checked={filesToDownload.includes(file.blobUri)}
                      />
                    </ListItem>
                    {(isRegistration || approval) && (
                      <ListItemIcon
                        style={{ paddingRight: 10, width: 'auto', minWidth: 0 }}
                      >
                        {getStatusIcon(file.status)}
                      </ListItemIcon>
                    )}
                    <ListItemText
                      key={file.id}
                      primary={
                        <Tooltip title={file?.originalFileName}>
                          <Box>
                            {file?.originalFileName.length > 33
                              ? `${file?.originalFileName.substring(0, 30)}...`
                              : file?.originalFileName}
                          </Box>
                        </Tooltip>
                      }
                      secondary={formatDateAndTimeLocal(file.uploadedOnUtc)}
                    />
                    {((isInternal && approval) ||
                      (certificationPeriodSelected.id === null &&
                        (isInternal ||
                          (file.status === 'Pending Acceptance' &&
                            !isInternal)))) && (
                      // eslint-disable-next-line react/jsx-indent
                      <ListItemSecondaryAction>
                        <IconButton
                          key={file.id}
                          id="positioned-button"
                          aria-controls={open ? 'positioned-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(event) => handleClick(event, file)}
                        >
                          <MoreVert key={file.id} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                )
              })}
            </List>
          ) : (
            <>
              {!filesData ? (
                <Box ml={2}>
                  <Skeleton height={35} width={330} />
                  <Skeleton height={35} width={330} />
                  <Skeleton height={35} width={330} />
                  <Skeleton height={35} width={330} />
                  <Skeleton height={35} width={330} />
                </Box>
              ) : (
                <Typography>There are no files uploaded</Typography>
              )}
            </>
          )}
        </Box>
        {(getFilesUploadedQuery.isLoading ||
          getRepoFilesUploadedQuery.isLoading ||
          GetRepoFilesQuery.isLoading) && (
          <Box ml={9}>
            <Skeleton height={35} width={330} />
            <Skeleton height={35} width={330} />
            <Skeleton height={35} width={330} />
            <Skeleton height={35} width={330} />
            <Skeleton height={35} width={330} />
          </Box>
        )}

        {filesToDownload.length > 0 && (
          <Box display="flex">
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography style={{ marginTop: 10 }}>
                {' '}
                {`${filesToDownload.length} file${
                  filesToDownload.length === 1 ? '' : 's'
                } selected.`}{' '}
              </Typography>
            </Box>
            <Box ml={10}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setFilesToDownload([])}
              >
                Clear selection
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadFiles}
                disabled={isRefetching || isLoading}
              >
                Download
              </Button>
            </Box>
          </Box>
        )}

        {filesData && filesData.length > 0 && filesToDownload.length === 0 && (
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box display="flex" flexGrow={1}>
              <List style={{ lineHeight: '0.5rem', maxWidth: '400px' }}>
                <ListItem>
                  <Typography>
                    <b>Name: </b> {filesData[selectedIndex]?.originalFileName}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    <b>Status: </b> {filesData[selectedIndex]?.status}
                  </Typography>
                </ListItem>
                {filesData[selectedIndex]?.status === 'Accepted' && (
                  <ListItem>
                    <Typography>
                      <b>Accepted By: </b>{' '}
                      {filesData[selectedIndex]?.acceptedByUserName}
                    </Typography>
                  </ListItem>
                )}
                <ListItem>
                  <Typography>
                    <b> Uploaded By: </b>{' '}
                    {filesData[selectedIndex]?.uploadedByUserName}
                  </Typography>
                </ListItem>
                {filesData[selectedIndex]?.status === 'Accepted' && (
                  <ListItem>
                    <Typography>
                      <b>Expiration Date: </b>
                      {filesData[selectedIndex]?.expirationDateUtc
                        ? formatDateAndTimeLocal(
                            filesData[selectedIndex]?.expirationDateUtc,
                            getLocaleDateString()
                          )
                        : 'N/A'}
                    </Typography>
                  </ListItem>
                )}
                {filesData[selectedIndex].additionalName && (
                  <ListItem>
                    <Typography>
                      <b>Requested Document: </b>{' '}
                      {filesData[selectedIndex]?.additionalName}
                    </Typography>
                  </ListItem>
                )}
                {filesData[selectedIndex]?.status === 'Rejected' &&
                  filesData[selectedIndex]?.rejectionReason && (
                    <ListItem>
                      <Typography>
                        <b>Rejection Reason: </b>{' '}
                        {filesData[selectedIndex]?.rejectionReason}
                      </Typography>
                    </ListItem>
                  )}
              </List>
            </Box>
            <Box display="flex" justifyContent="right">
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownload}
              >
                Download
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {canApproveReject && isInternal && (
          <div>
            <MenuItem
              disabled={
                selectedFile?.status !== 'Pending Acceptance' || readOnly
              }
              onClick={() => {
                setOpenApproveDialog(true)
                setAction('Accept')
              }}
            >
              Accept
            </MenuItem>
            <MenuItem
              disabled={
                selectedFile?.status !== 'Pending Acceptance' || readOnly
              }
              onClick={() => {
                setOpenApproveDialog(true)
                setAction('Reject')
              }}
            >
              Reject
            </MenuItem>
          </div>
        )}
        {isInternal && !approval && (
          <div>
            <MenuItem
              disabled={selectedFile?.status === 'Rejected' || readOnly}
              onClick={() => {
                setOpenTransferDialog(true)
                setAction('Clone')
              }}
            >
              Clone
            </MenuItem>
            <MenuItem
              disabled={selectedFile?.status === 'Rejected' || readOnly}
              onClick={() => {
                setOpenTransferDialog(true)
                setAction('Transfer')
              }}
            >
              Transfer
            </MenuItem>
          </div>
        )}
        {certificationPeriodSelected.id === null &&
          (canDeleteAcceptedFile ||
            selectedFile?.status === 'Pending Acceptance') && (
            <div>
              <MenuItem
                disabled={selectedFile?.status === 'Rejected' || readOnly}
                onClick={() => {
                  setOpenDeleteDialog(true)
                  setAction('Delete')
                }}
              >
                Delete
              </MenuItem>
            </div>
          )}

        {isRegistration && isInternal && documentData?.allowAudioFile && (
          <MenuItem
            disabled={selectedFile?.status === 'Rejected'}
            onClick={() => {
              setAction('question')
              setOpenQuestionDialog(true)
            }}
          >
            Review questionnaire
          </MenuItem>
        )}
      </Menu>

      {canApproveReject && (
        <div>
          {selectedFile && ['Accept', 'Reject'].includes(action) && (
            <AcceptsRejectFileModal
              action={action}
              documentId={selectedFile.id}
              isLicense={false}
              requestId={
                approval
                  ? selectedFile.repositoryBusinessUploadRequestId
                  : selectedFile.businessUploadRequestId
              }
              open={openApproveDialog}
              Close={() => {
                handleClose()
                setOpenApproveDialog(false)
              }}
              approval={approval}
            />
          )}
        </div>
      )}

      {selectedFile && action === 'Delete' && (
        <DeleteFileModal
          documentId={selectedFile.id}
          open={openDeleteDialog}
          Close={() => {
            handleClose()
            setOpenDeleteDialog(false)
          }}
        />
      )}
      {selectedFile && action === 'question' && openQuestionDialog && (
        <ReviewQuestion
          businessUploadId={selectedFile.id}
          open={openQuestionDialog}
          close={() => {
            handleClose()
            setOpenQuestionDialog(false)
          }}
        />
      )}

      {selectedFile && ['Clone', 'Transfer'].includes(action) && (
        <DocumentTransferDialog
          action={action}
          fileData={selectedFile}
          documentId={documentData.id}
          allowAudioFile={documentData.allowAudioFile}
          open={openTransferDialog}
          Close={() => {
            handleClose()
            setOpenTransferDialog(false)
          }}
        />
      )}
    </>
  )
}

export default DocumentFiles
