import styled from 'styled-components'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Color from 'color'
import { DialogTitle, Box, withStyles, Tooltip } from '@material-ui/core'

interface SectionDesktopProps {
  flexgrow?: number | 1
  borderleft?: boolean
}

interface ListItemStyleProps {
  component?: React.ReactNode
  to?: string
  open?: boolean
  target?: string
}

export const Logo = styled.img`
  max-width: 266px;
  margin-left: 0px;
`

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`

export const AppBarStyle = styled(AppBar)`
  z-index: ${({ theme }): number => theme.zIndex.drawer + 1};
`

export const ToolbarStyles = styled(Toolbar)`
  min-height: 55px;
  display: flex;
`

export const SectionDesktop = styled.div<SectionDesktopProps>`
  display: none;
  ${({ theme }): any => theme.breakpoints.up('sm')} {
    display: flex;
    flex-grow: ${({ flexgrow }): any => flexgrow};
    justify-content: space-between;
    align-items: center;
    border-left: ${({ borderleft, theme }): string =>
      (borderleft && `1px solid ${theme.palette.grey[100]}`) || 'none'};
  }
`

export const ListItemStyle = styled(ListItem)<ListItemStyleProps>`
  max-height: 50px;
  .MuiListItemIcon-root {
    min-width: 40px;
    color: ${({ theme }): string => theme.palette.common.white};
  }
  .MuiListItemText-primary {
    color: ${({ theme }): string => theme.palette.common.white};
  }
  :hover {
    .MuiListItemText-primary {
      color: ${({ theme }): string => theme.palette.common.black};
    }
    .MuiListItemIcon-root > .MuiSvgIcon-root {
      color: ${({ theme }): string => theme.palette.common.black};
    }
    color: ${({ theme }): string => theme.palette.common.white};
    background-color: ${({ theme }): string => theme.palette.common.white};
  }
  &.Mui-selected {
    .MuiListItemText-primary {
      color: ${({ theme }): string => theme.palette.common.black};
    }
    background-color: ${({ theme }): string => theme.palette.common.white};
    color: ${({ theme }): string => theme.palette.common.white};
    & > .MuiListItemIcon-root > .MuiSvgIcon-root {
      color: ${({ theme }): string => theme.palette.common.black};
    }
    &:hover {
      color: ${({ theme }): string => theme.debtColors.black};
      background-color: ${({ theme }): string => theme.palette.common.white};
      .MuiListItemIcon-root.MuiListItemIcon-root {
        color: ${({ theme }): string => theme.palette.common.black};
      }
  }
`

export const ListItemNested = styled(ListItem)<ListItemStyleProps>`
  padding-left: ${({ theme, open }): string =>
    open ? `${theme.spacing(8)}px` : `${theme.spacing(4)}px`};
  max-height: 50px;
`

export const ListSubMenu = styled(List)`
  background-color: ${({ theme }): string => theme.debtColors.debtGreyClean};
`

export const DrawerStyle = styled(Drawer)`
  width: ${({ open }): string => (open ? '240px' : '50px')};
  flex-shrink: 0;
  & .MuiDrawer-paper {
    width: ${({ open }): string => (open ? '280px' : '50px')};
    border: 0;
    box-shadow: inset ${({ theme }): string => theme.shadows[2]};
    background-color: #002e3a;
    overflow-x: hidden;
  }
  .toolbar-custom {
    ${({ theme }) => theme.breakpoints.up('sm')} {
      min-height: 35px;
      background-color: ${({ theme }): any =>
        Color(theme.palette.common.white).alpha(0.2).lighten(0.2)};
    }
  }
`

export const Main = styled.main`
  flex-grow: 1;
  margin-top: 34px;
  box-shadow: inset 0px 2px 2px -1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
`

export const ListNotify = styled(List)`
  width: 100%;
  max-width: 660px;
`

export const DialogTitleStyled = styled(DialogTitle)`
  background-color: ${({ theme }): string => theme.debtColors.debtTea};
`

export const FooterBox = styled.div`
  background-color: #ffffff;
  ${({ theme }): any => theme.breakpoints.down('sm')} {
    padding-bottom: initial;
    padding-left: 20px;
    padding-top: 30px;
  }

  ${({ theme }): any =>
    theme.breakpoints.up('sm') && theme.breakpoints.down('md')} {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  ${({ theme }): any =>
    theme.breakpoints.up('md') && theme.breakpoints.down('lg')} {
    display: flex;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 40px;
  }
`
export const TypographyBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ theme }): any => theme.breakpoints.down('sm')} {
    text-align: center;
    flex-direction: column;
  }
  ${({ theme }): any =>
    theme.breakpoints.up('sm') && theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ContentBox = styled(Box)`
  display: flex;
  width: inherit;
  color: #ffffff;
  flex-direction: column;
  width: -webkit-fill-available;
  width: -moz-available;
`

export const CustomTypography = styled.p`
  font-size: 0.9rem;
  color: #03033c;
  margin: 6px;
`

export const UploadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }): string => theme.palette.grey[300]};
  .upload-item-info {
    display: flex;
    align-items: center;
  }
  .upload-item-icon {
    margin-right: 0.4rem;
  }
`
export const WidthFixedTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 180,
  },
}))(Tooltip)
