import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  AssignmentInd,
  AssignmentLate,
  CancelRounded,
  Check,
  CheckCircle,
  ErrorRounded,
  LibraryAddCheck,
  NotificationsActive,
} from '@material-ui/icons'
import DescriptionIcon from '@material-ui/icons/Description'
import DeleteIcon from '@material-ui/icons/Delete'
import PublishIcon from '@material-ui/icons/Publish'
import axios from 'axios'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import UploadDragDrop from 'src/components/UploadDragDrop'
import { SELLER, USER_ID } from 'src/configs/AuthService'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AuthContext } from 'src/context/AuthenticationContext'
import { BusinessContext } from 'src/context/BusinessContext'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'
import { ICertificationPeriod } from 'src/api/models'
import { ConfirmDialog } from 'src/components/Dialogs'
import Skeleton from '@material-ui/lab/Skeleton'

import { CloneSection } from 'src/components/CloneSection'
import { UploadItem } from 'src/layouts/Main/styles'
import { MaxFileSize, PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import DocumentDetail from './DocumentDetail/DocumentDetail'

interface DocumentCardProps {
  documentData: any
  origin: string
  businessUsers: any[] | null
  disableItems: boolean
}

interface RequestDetails {
  filesUploaded: string
  totalComments: string
  lastUploadDate: string
  lastCommentDate: string
}
const MAX_SIZE_FILE = MaxFileSize

const DocumentCard: React.FC<DocumentCardProps> = ({
  documentData,
  origin,
  businessUsers,
  disableItems,
}: DocumentCardProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const userToken = GetAccessToken()
  const businessContext = useContext<any>(BusinessContext)
  const certificationPeriodSelected = useContext<ICertificationPeriod>(
    CertificationPeriodContext
  )
  const [openCopyDialog, setOpenCopyDialog] = useState<boolean>(false)
  const [openCloneDialog, setOpenCloneDialog] = useState<boolean>(false)

  const [chooseTab, setChooseTab] = useState('')
  const [files, setFiles] = useState<File[] | null>(null)
  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const [openDetailDialog, setOpenDetailDialog] = useState<boolean>(false)
  const [openApprovalDetailDialog, setOpenApprovalDetailDialog] =
    useState<boolean>(false)
  const [openAssignDocumentDialog, setOpenAssignDocumentDialog] =
    useState<boolean>(false)
  const { user } = useContext(AuthContext)
  const [userSelected, setUserSelected] = useState<any>()
  const userId = user.profile[USER_ID]
  const queryClient = useQueryClient()
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const isSeller = userPermissions.type === SELLER
  const [documentRequestData, setDocumentRequestData] = useState<any>()
  const [approvalDocuments, setApprovalDocuments] = useState<any[]>([])
  const ability = useContext(AbilityContext)
  const readOnly = ability.can(
    PermissionCodeAccess.ComplianceManagementSystem_Basic_ViewAccess,
    'any'
  )

  const isRegistration = origin === 'registration'

  const [optionAssign, setOptionAssign] = useState('assign')

  const notistackSuccess = notistackOptions('success')

  const notistackError = notistackOptions('error')

  const [assigningDocument, setAssigningDocument] = useState<boolean>(false)

  const getDocumentName = () => {
    if (documentData.name.length > 35)
      return (
        <Tooltip title={documentData.name} placement="top-end">
          <Box> {documentData.name.substring(0, 35)}...</Box>
        </Tooltip>
      )

    return documentData.name
  }

  const getSubheader = () => {
    if (documentData.description && documentData.description.length > 120)
      return (
        <Tooltip title={documentData.description} placement="top-end">
          <Box>{documentData.description.substring(0, 120)}...</Box>
        </Tooltip>
      )

    return documentData.description
  }

  const { isLoading: loadingApproval } = useQuery({
    queryKey: ['approvalDocumentsQuery', documentData.id, businessContext.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/repository/GetApprovalDocuments`,
        params: {
          businessId: businessContext.id,
          documentId: documentData.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setApprovalDocuments(result.data)
      })
    },
    cacheTime: 0,
    enabled: isInternal && !isRegistration,
  })

  const { isLoading: loadingDocs } = useQuery({
    queryKey: [
      'documentRequest',
      documentData,
      businessContext.id,
      certificationPeriodSelected.certificationDate,
    ],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/BusinessUploadRequest/GetAll`,
        params: {
          documentId: documentData.id,
          businessId: businessContext.id,
          certificationPeriodId: certificationPeriodSelected.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setDocumentRequestData(result.data)
      })
    },
    enabled: isRegistration,
    cacheTime: 0,
  })

  const { isLoading: loadingRepo } = useQuery({
    queryKey: [
      'documentRepositoryRequest',
      documentData,
      businessContext.id,
      certificationPeriodSelected.certificationDate,
    ],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/Repository/GetAll`,
        params: {
          documentId: documentData.id,
          businessId: businessContext.id,
          certificationPeriodId: certificationPeriodSelected.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setDocumentRequestData(result.data)
      })
    },
    enabled: !isRegistration,
    cacheTime: 0,
  })

  const getAssignedUsers = useQuery({
    queryKey: ['getAssignedUsers', documentData.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/Documents/assignedUsers/${documentData.id}`,

        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
    enabled: isRegistration,
  })

  const assignedUsersData = getAssignedUsers.data

  const handleUserSelected = (selectedUser: any) => {
    setUserSelected(selectedUser)
    setOptionAssign(
      usersToAssign?.includes(selectedUser) ? 'assign' : 'unassign'
    )
    setOpenAssignDocumentDialog(true)
    setAnchorEl(null)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorAction, setAnchorAction] = React.useState<null | HTMLElement>(
    null
  )

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleActionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorAction(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setAnchorAction(null)
  }

  const handleDownloadAllClick = () => {
    enqueueSnackbar(
      documentRequestData.businessUploads.length === 1
        ? 'Downloading file.'
        : 'Creating zip file. This process may take some time.',
      notistackSuccess
    )
    refetchGetFileZip()
    setAnchorAction(null)
  }

  const handleRejectReport = () => {
    enqueueSnackbar('Creating report.', notistackSuccess)
    refetchReport()
    setAnchorAction(null)
  }

  const assignUserDocument = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/userDocument`,
        params: {
          userId: userSelected.id,
          documentId: documentData.id,
          businessId: businessContext?.id,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar(
            optionAssign === 'assign'
              ? 'Documents assigned to user'
              : 'Document unassigned from user',
            notistackSuccess
          )
          setOpenAssignDocumentDialog(false)
          setAssigningDocument(false)
          setUserSelected(undefined)
          queryClient.refetchQueries('getAssignedUsers')
        })
        .catch((error: any) => {
          enqueueSnackbar(error.response.data, notistackError)
          setOpenAssignDocumentDialog(false)
          setAssigningDocument(false)
          setUserSelected(undefined)
        })
    },
    onError: () => {
      enqueueSnackbar('Error on generating due diligence deck', notistackError)
    },
  })

  const handleAssignDocumentClose = () => {
    setUserSelected(undefined)
    setOpenAssignDocumentDialog(false)
  }

  const {
    isLoading,
    refetch: refetchGetFileZip,
    isRefetching,
  } = useQuery({
    queryKey: ['getFileZip', documentRequestData?.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/BusinessUploadRequest/getFileZip/${documentRequestData.id}`,

        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
    onSuccess: (data) => {
      window.location.href = data
    },
    enabled: false,
  })

  const { refetch: refetchReport } = useQuery({
    queryKey: ['getRejectReport', documentRequestData?.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/BusinessUploadRequest/getRejectReport`,
        params: {
          businessUploadRequestId: documentRequestData.id,
          name: businessContext.name,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
    onSuccess: (data) => {
      if (data === '')
        enqueueSnackbar(
          "There aren't reject files to this section.",
          notistackSuccess
        )
      else window.location.href = data
    },

    enabled: false,
  })

  const getTimeLabel = (actionDate: Date): string => {
    const localTime = new Date()

    const diffHours = differenceInHours(localTime, actionDate)

    if (diffHours < 24) {
      if (diffHours < 1) {
        const diffMinutes = differenceInMinutes(localTime, actionDate)
        return `${diffMinutes} minute(s) ago`
      }

      return `${diffHours} hour(s) ago`
    }

    const diffDays = differenceInDays(localTime, actionDate)

    return `${diffDays} day(s) ago`
  }

  const handleDroppedFiles = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles)
  }
  const handleAssignDocument = () => {
    assignUserDocument.mutate()
  }

  const handleCopySection = () => {
    copySection.mutate()
  }

  const copySection = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/businessUpload/CopySection`,
        params: {
          documentId: documentData.id,
          businessId: businessContext?.id,
          certificationPeriodId: certificationPeriodSelected.id,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar(
            'Section copied to current certification.',
            notistackSuccess
          )
          setOpenCopyDialog(false)
        })
        .catch((error: any) => {
          enqueueSnackbar(error.response.data, notistackError)
          setOpenCopyDialog(false)
        })
    },
    onError: () => {
      enqueueSnackbar('Error on copying section.', notistackError)
    },
  })

  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notistackOptions('warning'))
  }

  const getDetails = (): RequestDetails => {
    const details: RequestDetails = {
      filesUploaded: 'N/A',
      totalComments: 'N/A',
      lastCommentDate: 'N/A',
      lastUploadDate: 'N/A',
    }

    if (documentRequestData) {
      if (
        documentRequestData.businessUploads &&
        documentRequestData.businessUploads?.length > 0
      ) {
        details.filesUploaded = documentRequestData.businessUploads?.length
        details.lastUploadDate = getTimeLabel(
          new Date(
            Math.max(
              ...documentRequestData.businessUploads?.map(
                (e: any) => new Date(e.uploadedOnUtc)
              )
            )
          )
        )
      }

      if (
        documentRequestData.businessUploadComments &&
        documentRequestData.businessUploadComments?.length > 0
      ) {
        details.totalComments =
          documentRequestData.businessUploadComments.length
        details.lastCommentDate = getTimeLabel(
          new Date(
            Math.max(
              ...documentRequestData.businessUploadComments?.map(
                (e: any) => new Date(e.creationDateUtc)
              )
            )
          )
        )
      }
    }

    return details
  }

  const getStatusIcon = () => {
    if (documentRequestData) {
      if (documentRequestData.status === 'Rejected')
        return (
          <Tooltip title="Rejected">
            <CancelRounded style={{ color: 'red' }} />
          </Tooltip>
        )
      if (documentRequestData.status === 'Accepted')
        return (
          <Tooltip title="Accepted">
            <CheckCircle style={{ color: 'green' }} />
          </Tooltip>
        )
    }

    return (
      <Tooltip title={documentRequestData.status || 'Pending Upload'}>
        <ErrorRounded style={{ color: '#FFA500' }} />
      </Tooltip>
    )
  }

  const displayNotification = (): boolean => {
    const localDate = new Date()

    if (documentRequestData) {
      const maxUploadDate = new Date(
        Math.max(
          ...documentRequestData.businessUploads.map(
            (e: any) => new Date(e.uploadedOnUtc)
          )
        )
      )

      const maxCommentDate = new Date(
        Math.max(
          ...documentRequestData.businessUploadComments.map(
            (e: any) => new Date(e.creationDateUtc)
          )
        )
      )

      if (
        differenceInHours(localDate, maxUploadDate) < 1 ||
        differenceInHours(localDate, maxCommentDate) < 1
      )
        return true

      return false
    }

    return false
  }

  const mutationUploadFile = useMutation({
    mutationFn: async (formData: FormData) => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/businessupload`,
        data: formData,
        params: {
          documentId: documentData.id,
          businessId: businessContext?.id,
          businessName: businessContext?.name,
          businessType: businessContext?.type,
          buyerType: businessContext?.buyerType,
          userId,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async () => {
      setFiles(null)
      enqueueSnackbar(
        'File(s) uploaded successfully',
        notistackOptions('success')
      )
      queryClient.refetchQueries({
        queryKey: 'getfilesUploaded',
      })

      queryClient.refetchQueries({
        queryKey: 'documentRequest',
      })

      queryClient.refetchQueries({
        queryKey: 'getHistory',
      })

      queryClient.refetchQueries({
        queryKey: 'uploadProgressPercent',
      })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Error uploading file', notistackOptions('error'))
    },
  })

  const mutationUploadApprovalFile = useMutation({
    mutationFn: async (formData: FormData) => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/repository`,
        data: formData,
        params: {
          documentId: documentData.id,
          businessId: businessContext?.id,
          businessName: businessContext?.name,
          businessType: businessContext?.type,
          buyerType: businessContext?.buyerType,
          userId,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async () => {
      setFiles(null)
      enqueueSnackbar(
        'File(s) uploaded successfully',
        notistackOptions('success')
      )
      queryClient.refetchQueries({
        queryKey: 'approvalDocumentsQuery',
      })
    },
    onError: (error, variables, context) => {
      enqueueSnackbar('Error uploading file', notistackOptions('error'))
    },
  })

  const handleFileUpload = () => {
    if (files && files.length) {
      const formData = new FormData()

      files.forEach((file: File) => {
        formData.append('files', file, file.name)
      })

      if (isRegistration) mutationUploadFile.mutate(formData)
      else mutationUploadApprovalFile.mutate(formData)
    }
  }

  const handleUploadFileRemove = (index: number) => {
    if (files) {
      setFiles(files.filter((f) => f.name !== files[index].name))
    }
  }

  const userListIds = businessUsers?.map((d: any) => d.id.toLowerCase())

  const usersToAssign = businessUsers?.filter(
    (r: any) => !assignedUsersData?.includes(r.id.toLowerCase())
  )

  const handleSeeDetails = () => {
    setOpenDetailDialog(true)
  }
  const closeDetailDialog = () => {
    setChooseTab('')
    setOpenDetailDialog(false)
    setOpenApprovalDetailDialog(false)
  }

  if (getAssignedUsers.isLoading || loadingDocs || loadingRepo) {
    return (
      <>
        <Card style={{ width: '100%', height: '270px' }}>
          <Skeleton variant="rect" height="100%" width="100%" />
        </Card>
      </>
    )
  }
  const showUploadComponent =
    ((isInternal || isSeller) && !isRegistration) ||
    (isRegistration && !disableItems && certificationPeriodSelected.id === null)

  return (
    <>
      <Card style={{ width: '100%', height: '270px' }}>
        <CardHeader
          style={{ height: '80px' }}
          title={getDocumentName()}
          subheader={getSubheader()}
          action={
            <Box display="flex">
              {isRegistration && displayNotification() && (
                <Tooltip title="There is new actions in the last hour">
                  <IconButton aria-label="settings">
                    <NotificationsActive style={{ color: '#5398dd' }} />
                  </IconButton>
                </Tooltip>
              )}
              {isRegistration && documentData.isRequired && (
                <Tooltip title="Required">
                  <IconButton aria-label="settings">
                    <AssignmentLate />
                  </IconButton>
                </Tooltip>
              )}
              {!readOnly &&
                isInternal &&
                !isRegistration &&
                approvalDocuments.length > 0 && (
                  <Tooltip title="Approve Documents">
                    <IconButton
                      aria-label="settings"
                      onClick={() => setOpenApprovalDetailDialog(true)}
                    >
                      <LibraryAddCheck style={{ color: '#5398dd' }} />
                    </IconButton>
                  </Tooltip>
                )}
              {isRegistration &&
                !isInternal &&
                businessUsers &&
                businessUsers.length > 1 &&
                userListIds &&
                userListIds.length > 1 && (
                  <Tooltip
                    placement="left-end"
                    title={
                      assignedUsersData &&
                      assignedUsersData.find((r: any) =>
                        userListIds?.includes(r.toLowerCase())
                      ) ? (
                        <Box display="flex" flexDirection="column">
                          <Box>Document assigned to: </Box>
                          {assignedUsersData
                            ?.filter((f: any) =>
                              userListIds?.includes(f.toLowerCase())
                            )
                            ?.map((u: any) => {
                              return (
                                <Box key={`item${u}`}>
                                  {
                                    businessUsers?.find(
                                      (g: any) =>
                                        g.id.toLowerCase() === u.toLowerCase()
                                    ).name
                                  }
                                </Box>
                              )
                            })}
                        </Box>
                      ) : (
                        <Box>Assign document to user</Box>
                      )
                    }
                  >
                    <Box>
                      <div key="divAssignDocument">
                        <IconButton
                          ref={anchorRef}
                          onClick={handleClick}
                          aria-label="settings"
                        >
                          <AssignmentInd
                            color={
                              assignedUsersData &&
                              assignedUsersData.find((r: any) =>
                                userListIds?.includes(r.toLowerCase())
                              ) &&
                              'primary'
                            }
                          />
                        </IconButton>
                        <Menu
                          id="assign-category-menu"
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          getContentAnchorEl={null}
                        >
                          {businessUsers.map((u: any) => (
                            <MenuItem
                              key={`menuItem ${u.id}`}
                              onClick={() => handleUserSelected(u)}
                            >
                              <Box display="flex">
                                <Box style={{ minWidth: '20px' }}>
                                  {!usersToAssign?.find(
                                    (assignedUserI) =>
                                      assignedUserI.id.toLowerCase() ===
                                      u.id.toLowerCase()
                                  ) ? (
                                    <Check
                                      style={{ marginRight: 1 }}
                                      fontSize="small"
                                    />
                                  ) : (
                                    <Box style={{ marginRight: 5 }}> </Box>
                                  )}
                                </Box>
                                <Box>{u.name}</Box>
                              </Box>
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </Box>
                  </Tooltip>
                )}
              {isRegistration && (
                <IconButton aria-label="settings">{getStatusIcon()}</IconButton>
              )}
            </Box>
          }
        />
        <Divider light style={{ height: '0.5px' }} />
        <CardContent style={{ height: '140px' }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            justifyItems="center"
          >
            {documentRequestData ? (
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                  <Typography>Files: </Typography>
                  <Typography
                    style={{ cursor: 'pointer', marginLeft: 2 }}
                    onClick={() => {
                      setChooseTab('1')
                      handleSeeDetails()
                    }}
                  >
                    {getDetails().filesUploaded}
                  </Typography>
                </Box>

                {isRegistration && (
                  <Box display="flex" flexDirection="row" mt={1}>
                    <Typography>Comments:</Typography>
                    <Typography
                      style={{ cursor: 'pointer', marginLeft: 2 }}
                      onClick={() => {
                        setChooseTab('2')
                        handleSeeDetails()
                      }}
                    >
                      {getDetails().totalComments}
                    </Typography>
                  </Box>
                )}
                {isRegistration && (
                  <Box mt={1}>
                    <Typography>
                      Last Upload: {getDetails().lastUploadDate}
                    </Typography>
                  </Box>
                )}
                {isRegistration && (
                  <Box mt={1}>
                    <Typography>
                      Last Comment: {getDetails().lastCommentDate}
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <Typography>No data uploaded</Typography>
              </Box>
            )}

            {showUploadComponent && (
              <Box
                display="flex"
                flexDirection="column"
                style={{ width: '50%', height: '100%' }}
              >
                {files && files.length > 0 && (
                  <UploadItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <List
                          style={{
                            height: '120px',
                            overflow: 'auto',
                            marginBottom: 4,
                            lineHeight: '0.5rem',
                          }}
                        >
                          {files.map((file: any, idx: number) => {
                            return (
                              <ListItem
                                alignItems="flex-start"
                                key={`item_${idx}`}
                              >
                                <div
                                  className="upload-item-info"
                                  key={`div_${idx}`}
                                >
                                  <DescriptionIcon
                                    key={`DescriptionIcon_${idx}`}
                                    fontSize="small"
                                    color="primary"
                                    className="upload-item-icon"
                                  />
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="span"
                                    key={`Typography_${file.name}`}
                                  >
                                    {file.name.length > 18
                                      ? `${file.name.substring(0, 15)}...`
                                      : file.name}
                                  </Typography>
                                  <IconButton
                                    size="small"
                                    key={`Button_${idx}`}
                                    onClick={() => {
                                      handleUploadFileRemove(idx)
                                    }}
                                  >
                                    <DeleteIcon
                                      key={`DeleteButton_${idx}`}
                                      className="upload-item-icon"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </div>
                              </ListItem>
                            )
                          })}
                        </List>
                      </Grid>
                    </Grid>
                  </UploadItem>
                )}
                {!readOnly && (files == null || files.length === 0) ? (
                  <Box id="box2" display="flex" justifyContent="end">
                    <UploadDragDrop
                      size="large"
                      linkText="Click to Upload"
                      text={
                        documentData.allowAudioFile
                          ? '.mp3 or .wav files'
                          : 'PDF files only (max. 200mb)'
                      }
                      multiple={true}
                      uploading={mutationUploadFile.isLoading}
                      onDrop={(acceptedFiles) => {
                        handleDroppedFiles(acceptedFiles)
                      }}
                      maxSize={MAX_SIZE_FILE}
                      onDropRejected={handleFileRejected}
                      accept={
                        documentData.allowAudioFile
                          ? '.mp3,.wav'
                          : 'application/pdf'
                      }
                    />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="end"
                  >
                    <Box>
                      <Button
                        style={{ marginTop: 5 }}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleFileUpload()
                        }}
                        disabled={mutationUploadFile.isLoading || readOnly}
                        startIcon={
                          mutationUploadFile.isLoading ? (
                            <CircularProgress size={15} color="primary" />
                          ) : (
                            <PublishIcon fontSize="small" />
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </CardContent>
        <Box>
          <CardActions style={{ padding: '10px' }}>
            <Button
              size="small"
              style={{ color: '#5398dd', fontWeight: 'bold' }}
              onClick={() => {
                setChooseTab('')
                handleSeeDetails()
              }}
              disabled={loadingDocs || loadingRepo}
            >
              SEE DETAILS
            </Button>
            <Button
              size="small"
              disabled={
                loadingDocs ||
                loadingRepo ||
                isLoading ||
                isRefetching ||
                readOnly
              }
              variant="contained"
              color="primary"
              ref={anchorRef}
              onClick={handleActionClick}
            >
              Actions
            </Button>
            <Menu
              id="action-menu"
              anchorEl={anchorAction}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              open={Boolean(anchorAction)}
              onClose={handleClose}
              getContentAnchorEl={null}
            >
              <MenuItem
                disabled={
                  !documentRequestData ||
                  (documentRequestData &&
                    documentRequestData.businessUploads.length === 0)
                }
                onClick={() => handleDownloadAllClick()}
              >
                Download all
              </MenuItem>

              {isRegistration &&
                isInternal &&
                certificationPeriodSelected.id !== null && (
                  <MenuItem
                    onClick={() => {
                      handleClose()
                      setOpenCopyDialog(true)
                    }}
                    disabled={
                      !documentRequestData ||
                      (documentRequestData &&
                        documentRequestData.businessUploads.length === 0)
                    }
                  >
                    Copy to current certification
                  </MenuItem>
                )}
              {isRegistration &&
                isInternal &&
                certificationPeriodSelected.id === null && (
                  <MenuItem
                    onClick={() => {
                      handleClose()
                      setOpenCloneDialog(true)
                    }}
                    disabled={
                      !documentRequestData ||
                      (documentRequestData &&
                        documentRequestData.businessUploads.length === 0)
                    }
                  >
                    Clone documents
                  </MenuItem>
                )}

              <MenuItem
                disabled={
                  !documentRequestData ||
                  (documentRequestData &&
                    documentRequestData.businessUploads.length === 0)
                }
                onClick={() => handleRejectReport()}
              >
                Reject report
              </MenuItem>
            </Menu>
          </CardActions>
        </Box>
      </Card>

      {openCloneDialog && (
        <CloneSection
          close={() => setOpenCloneDialog(false)}
          businessId={businessContext?.id}
          open={openCloneDialog}
          documentId={documentData.id}
          allowAudioFile={documentData.allowAudioFile}
        />
      )}
      {openDetailDialog && (
        <DocumentDetail
          documentRequest={documentRequestData?.id}
          documentData={documentData}
          open={openDetailDialog}
          close={closeDetailDialog}
          selectTab={chooseTab}
          isRegistration={isRegistration}
          approval={false}
        />
      )}
      {openApprovalDetailDialog &&
        !loadingApproval &&
        approvalDocuments.length > 0 && (
          <DocumentDetail
            documentRequest={
              approvalDocuments[0]?.repositoryBusinessUploadRequestId
            }
            documentData={approvalDocuments}
            open={openApprovalDetailDialog}
            close={closeDetailDialog}
            selectTab={chooseTab}
            isRegistration={isRegistration}
            approval={true}
          />
        )}
      {openAssignDocumentDialog && (
        <ConfirmDialog
          open={openAssignDocumentDialog}
          title={
            usersToAssign?.includes(userSelected)
              ? 'Assign Document'
              : 'Unassign Document'
          }
          loading={assigningDocument}
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                {usersToAssign?.includes(userSelected)
                  ? `Are you sure you want to assign this document to ${userSelected.name}?`
                  : `Are you sure you want to unassign this document from ${userSelected.name}?`}
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={handleAssignDocumentClose}
          onConfirm={handleAssignDocument}
        />
      )}

      {openCopyDialog && (
        <ConfirmDialog
          open={openCopyDialog}
          title="Copy Section"
          loading={copySection.isLoading}
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                Are you sure you want to copy all documents from this section to
                current certification?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenCopyDialog(false)}
          onConfirm={handleCopySection}
        />
      )}
    </>
  )
}

export default DocumentCard
