import * as Yup from 'yup'

const rePhoneNumber =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

const reAmount = /^(\d{1,3}(,\d{3})?|\d{1,3}(,\d{3}){2})\.\d{2}$/

export const getValidations = (formType: string) => {
  if (formType === 'bank-account')
    return Yup.object().shape({
      bankingName: Yup.string().required('Required'),
      accountType: Yup.string().required('Required'),
      accountNumber: Yup.string().required('Required'),
      routingNumber: Yup.string().required('Required'),
      nameAccount: Yup.string().required('Required'),
      street: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      bankPhone: Yup.string()
        .required('Required')
        .matches(rePhoneNumber, 'Phone number is invalid'),
      postalCode: Yup.string().test(
        'len',
        'Invalid postal code',
        (val) => val !== undefined && val?.toString().length < 11
      ),
      status: Yup.string().required('Required'),
      optionBillingDepartment: Yup.string().required('Required'),
      billingEmailAddress: Yup.string()
        .nullable()
        .when('optionBillingDepartment', {
          is: 'Yes',
          then: Yup.string().required('Required').email('Invalid e-mail'),
        }),
    })
  if (formType === 'disclosure-authorization')
    return Yup.object().shape({
      fullName: Yup.string().required('Required'),
      title: Yup.string().required('Required'),
      formDate: Yup.date()
        .nullable()
        .typeError('Invalid Date')
        .required('Required')
        .max(new Date(), 'Invalid Date'),
    })

  if (formType === 'executives')
    return Yup.object().shape({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      jobTitle: Yup.string().required('Required'),
      phone: Yup.string()
        .required('Required')
        .matches(rePhoneNumber, 'Phone number is invalid'),
      emailAddress: Yup.string().required('Required').email('Invalid e-mail'),
      last4Ssn: Yup.string()
        .required('Required')
        .test(
          'len',
          'Must be exactly 4 characters',
          (val) => val !== undefined && val?.length === 4
        )
        .test(
          'more than 0',
          'Last SSN must be between 1 and 9999',
          (val) => val !== undefined && val?.toString() !== '0000'
        ),

      dateOfBirth: Yup.date()
        .nullable()
        .typeError('Invalid Date')
        .required('Required')
        .max(new Date(), 'Invalid Date')
        .min(new Date(1900, 1, 1), 'Invalid Date'),
    })
  if (formType === 'company-registrations-trading-names')
    return Yup.object().shape({
      fcaRegistration: Yup.string().required('Required'),
      effectiveDate: Yup.date()
        .nullable()
        .typeError('Invalid Date')
        .max(new Date(), 'Invalid Date'),
    })
  if (formType === 'compliance-request')
    return Yup.object().shape({
      fullName: Yup.string().required('Required'),
      title: Yup.string().required('Required'),
      yearsOfExperience: Yup.number()
        .min(0, 'The number must be between 0 and 99.')
        .max(99, 'The number must be between 0 and 99.')
        .required('Required'),
      yearsWithYourCompany: Yup.number()
        .min(0, 'The number must be between 0 and 99.')
        .max(99, 'The number must be between 0 and 99.')
        .required('Required'),
      telephone: Yup.string()
        .required('Required')
        .matches(rePhoneNumber, 'Telephone number is invalid'),
      emailAddress: Yup.string().required('Required').email('Invalid e-mail'),
    })

  if (formType === 'compliance-legal-regulatory')
    return Yup.object().shape({
      complianceOfficerfullName: Yup.string().required('Required'),
      complianceOfficerTitle: Yup.string().required('Required'),
      complianceOfficerYearsOfExperience: Yup.number()
        .min(0, 'The number must be between 0 and 99.')
        .max(99, 'The number must be between 0 and 99.')
        .required('Required'),
      complianceOfficerYearsWithYourCompany: Yup.number()
        .min(0, 'The number must be between 0 and 99.')
        .max(99, 'The number must be between 0 and 99.')
        .required('Required'),
      complianceOfficerTelephone: Yup.string()
        .required('Required')
        .matches(rePhoneNumber, 'Telephone number is invalid'),
      complianceOfficerEmailAddress: Yup.string()
        .required('Required')
        .email('Invalid e-mail'),
      managerFullName: Yup.string().required('Required'),
      managertitle: Yup.string().required('Required'),
      manageryearsOfExperience: Yup.number()
        .min(0)
        .max(99)
        .required('Required'),
      manageryearsWithYourCompany: Yup.number()
        .min(0, 'The number must be between 0 and 99.')
        .max(99, 'The number must be between 0 and 99.')
        .required('Required'),
      managertelephone: Yup.string()
        .required('Required')
        .matches(rePhoneNumber, 'Telephone number is invalid'),
      manageremailAddress: Yup.string()
        .required('Required')
        .email('Invalid e-mail'),
      resolutionComplaint1fullName: Yup.string().required('Required'),
      resolutionComplaint1Title: Yup.string().required('Required'),
      resolutionComplaint1YearsOfExperience: Yup.number()
        .min(0, 'The number must be between 0 and 99.')
        .max(99, 'The number must be between 0 and 99.')
        .required('Required'),
      resolutionComplaint1YearsWithYourCompany: Yup.number()
        .min(0, 'The number must be between 0 and 99.')
        .max(99, 'The number must be between 0 and 99.')
        .required('Required'),
      resolutionComplaint1Telephone: Yup.string()
        .required('Required')
        .matches(rePhoneNumber, 'Telephone number is invalid'),
      resolutionComplaint1EmailAddress: Yup.string()
        .required('Required')
        .email('Invalid e-mail'),
      resolutionComplaint2FullName: Yup.string().required('Required'),
      resolutionComplaint2title: Yup.string().required('Required'),
      resolutionComplaint2yearsOfExperience: Yup.number()
        .min(0, 'The number must be between 0 and 99.')
        .max(99, 'The number must be between 0 and 99.')
        .required('Required'),
      resolutionComplaint2yearsWithYourCompany: Yup.number()
        .min(0, 'The number must be between 0 and 99.')
        .max(99, 'The number must be between 0 and 99.')
        .required('Required'),
      resolutionComplaint2telephone: Yup.string()
        .required('Required')
        .matches(rePhoneNumber, 'Telephone number is invalid'),
      resolutionComplaint2emailAddress: Yup.string()
        .required('Required')
        .email('Invalid e-mail'),
      qtyConsumerComplaints: Yup.number().min(0).max(9999).required('Required'),
      notes: Yup.string().nullable(),
      optionRegulatoryEnforcement: Yup.string().required('Required'),
      optionNamedDefendant: Yup.string().required('Required'),
      optionNamedDefendantBreachConsumer: Yup.string().required('Required'),
      optionNamedDefendantAnyClass: Yup.string().required('Required'),
      optionPendingLitigation: Yup.string().required('Required'),
      optionAccountLitigation: Yup.string().required('Required'),
      optionAccountCollection: Yup.string().required('Required'),
      optionRequireRecordCalls: Yup.string().required('Required'),
      optionRecordCallsRetrieve: Yup.string().required('Required'),
      optionAuditThirdParty: Yup.string().required('Required'),
      optionCreditBureau: Yup.string().required('Required'),
      optionAccountBalances: Yup.string().required('Required'),
    })

  if (formType === 'business-information')
    return Yup.object().shape({
      companyName: Yup.string().required('Required'),
      physicalAddress: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zipcode: Yup.string()
        .required('Required')
        .test(
          'len',
          'Invalid zip code',
          (val) => val !== undefined && val?.toString().length < 11
        ),
      mailingAddress: Yup.string().when('samePhysicalAddress', {
        is: false,
        then: Yup.string().required('Required'),
      }),
      mailingCity: Yup.string().when('samePhysicalAddress', {
        is: false,
        then: Yup.string().required('Required'),
      }),
      mailingState: Yup.string().when('samePhysicalAddress', {
        is: false,
        then: Yup.string().required('Required'),
      }),
      mailingZipcode: Yup.string()
        .when('samePhysicalAddress', {
          is: false,
          then: Yup.string().required('Required'),
        })
        .when('samePhysicalAddress', {
          is: false,
          then: Yup.string().test(
            'len',
            'Invalid zip code',
            (val) => val !== undefined && val?.toString().length < 11
          ),
        }),
      billingAddress: Yup.string().when('samePhysicalAddressToBilling', {
        is: false,
        then: Yup.string().required('Required'),
      }),
      billingCity: Yup.string().when('samePhysicalAddressToBilling', {
        is: false,
        then: Yup.string().required('Required'),
      }),
      billingState: Yup.string().when('samePhysicalAddressToBilling', {
        is: false,
        then: Yup.string().required('Required'),
      }),
      billingZipcode: Yup.string()
        .when('samePhysicalAddressToBilling', {
          is: false,
          then: Yup.string().required('Required'),
        })
        .when('samePhysicalAddressToBilling', {
          is: false,
          then: Yup.string().test(
            'len',
            'Invalid zip code',
            (val) => val !== undefined && val?.toString().length < 11
          ),
        }),
      consumerServiceTelephone: Yup.string()
        .required('Required')
        .matches(rePhoneNumber, 'Telephone number is invalid'),
      companyWebsiteAddress: Yup.string().required('Required'),
      dateOfEstablishment: Yup.date()
        .nullable()
        .typeError('Invalid Date')
        .required('Required')
        .max(new Date(), 'Invalid Date'),
      stateofIncorporation: Yup.string().required('Required'),
      taxID: Yup.string().required('Required'),
      businessType: Yup.string().required('Required'),
    })

  if (formType === 'information-security')
    return Yup.object().shape({
      hadPreviousSecurityAssessments: Yup.string().required('Required'),
      lastDateAssessment: Yup.date()
        .nullable()
        .when('hadPreviousSecurityAssessments', {
          is: '1',
          then: Yup.date()
            .typeError('Invalid Date')
            .required('Required')
            .max(new Date(), 'Invalid Date'),
        }),

      lastAuditCompany: Yup.string().required('Required'),
      frequencyAssessment: Yup.string().required('Required'),
      optionAcknowlodgeSecurityPolicies: Yup.string().required('Required'),
      optionSecureSocketLayers: Yup.string().required('Required'),
      optionEncryptData: Yup.string().required('Required'),
      bitEncryption: Yup.string().nullable(),
      optionServerHoused: Yup.string().required('Required'),
      duration: Yup.string().required('Required'),
      otherDuration: Yup.string()
        .nullable()
        .when('duration', {
          is: 'Other',
          then: Yup.string().required('Required'),
        }),
      optionSecureFTP: Yup.string().required('Required'),
      optionPCIComplaint: Yup.string().required('Required'),
      level: Yup.string().required('Required'),
    })

  if (formType === 'insurance')
    return Yup.object().shape({
      insuranceProvider: Yup.string().required('Required'),
      limitCoverage: Yup.string()
        .required('Required')
        .matches(reAmount, 'Invalid number'),
      policy: Yup.string().required('Required'),
      startDate: Yup.date()
        .nullable()
        .required('Required')
        .typeError('Invalid Date'),
      endDate: Yup.date()
        .nullable()
        .typeError('Invalid Date')
        .required('Required')
        .min(Yup.ref('startDate'), 'Invalid Date'),
      additionalInsurance: Yup.string().required('Required'),
    })

  if (formType === 'references')
    return Yup.object().shape({
      companyName: Yup.string().required('Required'),
      contactName: Yup.string().required('Required'),
      contactPhone: Yup.string()
        .required('Required')
        .matches(rePhoneNumber, 'Telephone number is invalid'),
      companyname2: Yup.string().required('Required'),
      contactName2: Yup.string().required('Required'),
      contactPhone2: Yup.string()
        .required('Required')
        .matches(rePhoneNumber, 'Telephone number is invalid'),
      companyname3: Yup.string(),
      contactName3: Yup.string(),
      contactPhone3: Yup.string().matches(
        rePhoneNumber,
        'Telephone number is invalid'
      ),
      associations: Yup.string().required('Required'),
    })

  return null
}
