/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import axios from 'axios'
import * as React from 'react'
import { useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { BACKEND_URL } from 'src/api/axios/api'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import hardcodeData from 'src/utils/hardcodeData'
import { GetAccessToken } from 'src/api/axios/helper'
import { Close } from '@material-ui/icons'
import { DialogTitleStyled } from './style'
import { ConfirmDialog } from '../Dialogs'
import Loader from '../Loader'

interface FillableCommandCellProps {
  dataItem: any
  category: any[]
  confirmCallback: () => void
  updateSuccededCallback: () => void
}

interface IFillable {
  id: number
  categoryId: number | null
  type: string | undefined
  name: string
  aliasName: string
  description: string
  isRequired: boolean
  isEnabled: boolean
  businessTypeName: string[]
  validateBuyerTypeName: boolean
  buyerTypeName: string[]
}

const FillableCommandCell: React.FC<FillableCommandCellProps> = ({
  dataItem,
  category,
  confirmCallback,
  updateSuccededCallback,
}: FillableCommandCellProps) => {
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [openEditDialog, setOpenEditDialog] = React.useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const userToken = GetAccessToken()
  const [loading, setLoading] = useState(false)

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValues, setInitialValues] = React.useState<IFillable>({
    id: dataItem.id,
    type: dataItem?.type,
    name: dataItem.name,
    description: dataItem.description,
    categoryId: dataItem.categoryId,
    isRequired: dataItem.isRequired,
    aliasName: dataItem.aliasName,
    isEnabled: dataItem.isEnabled,
    businessTypeName: dataItem.businessTypeName,
    validateBuyerTypeName: dataItem.businessTypeName.includes('Buyer'),
    buyerTypeName: dataItem.buyerTypeName,
  })

  const inputDataFormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    categoryId: Yup.number().typeError('Required').required('Required'),
    isEnabled: Yup.boolean().required('Required'),
    isRequired: Yup.boolean().required('Required'),
    businessTypeName: Yup.array().min(1, 'Required'),
    validateBuyerTypeName: Yup.boolean(),
    buyerTypeName: Yup.array().when(
      'businessTypeName',
      (arrayData: string[], schema: any) =>
        arrayData.includes('Buyer') ? schema.min(1, 'Required') : schema
    ),
  })

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        id: values.id,
        type: values.type || null,
        name: values.name || null,
        aliasName: values.aliasName || null,
        categoryId: values.categoryId || null,
        description: values.description || null,
        isEnabled: values.isEnabled,
        isRequired: values.isRequired,
        businessTypeName: values.businessTypeName || null,
        buyerTypeName: values.buyerTypeName || null,
      }
      setLoading(true)
      axios({
        method: 'put',
        url: `${BACKEND_URL}/fillableForm`,
        data: requestValues,
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('Data updated', notistackSucces)
          setLoading(false)
          updateSuccededCallback()
        })
        .catch((error) => {
          setLoading(false)
          enqueueSnackbar(error.response.data, notistackError)
        })

      setSubmitting(false)
    },
  })

  const fillableFormArray: any[] = hardcodeData.getFillableForms()

  return (
    <>
      <td className="k-command-cell" style={{ paddingLeft: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            data-cy="grid-delete-button"
            onClick={() => {
              setOpenDeleteDialog(true)
            }}
          >
            <Delete />
          </IconButton>
          <IconButton
            data-cy="grid-edit-button"
            onClick={() => {
              setOpenEditDialog(true)
            }}
          >
            <Edit />
          </IconButton>
        </div>
      </td>
      <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false)
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <form onSubmit={inputDataForm.handleSubmit}>
          <DialogTitleStyled id="form-dialog-title">
            <Typography
              variant="h1"
              style={{ textAlign: 'center', color: 'white' }}
            >
              Fillable Form
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenEditDialog(false)
              }}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Close />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            {loading && <Loader />}
            <Grid
              container
              style={{
                height: '100%',
                width: '100%',
                maxWidth: '400px',
              }}
            >
              <Grid item xs={12} md={12}>
                <Typography component="div">
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box display="flex">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Typography>Enabled</Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Switch
                            data-cy="dialog-disable-enabled-selector"
                            checked={inputDataForm.values.isEnabled}
                            onChange={inputDataForm.handleChange}
                            name="isEnabled"
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Box display="flex">
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Typography>Required</Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <Switch
                            data-cy="dialog-required-not-required-selector"
                            checked={inputDataForm.values.isRequired}
                            onChange={inputDataForm.handleChange}
                            name="isRequired"
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>

              <Grid item xs={12} md={12} style={{ paddingTop: '5px' }}>
                <TextField
                  data-cy="dialog-category-dropdown"
                  id="select-request-type"
                  select
                  label="Category"
                  style={{ width: '100%' }}
                  name="categoryId"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={inputDataForm.handleChange}
                  value={inputDataForm.values.categoryId}
                  error={!!inputDataForm.errors.categoryId}
                  helperText={inputDataForm.errors.categoryId}
                >
                  <MenuItem value="">
                    <em>Select a category</em>
                  </MenuItem>
                  {category.map((dt: any) => {
                    return (
                      <MenuItem key={dt.name} value={dt.id}>
                        <Typography>{dt.name}</Typography>
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  data-cy="type"
                  id="type"
                  select
                  label="Type"
                  style={{ width: '100%' }}
                  name="type"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={inputDataForm.handleChange}
                  value={inputDataForm.values.type}
                  error={!!inputDataForm.errors.type}
                  helperText={inputDataForm.errors.type}
                >
                  <MenuItem value="">
                    <em>Select a Type</em>
                  </MenuItem>
                  {fillableFormArray?.map((u: any) => (
                    <MenuItem value={u.value} key={u.value}>
                      {u.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  data-cy="dialog-input-field-name"
                  label="Name"
                  name="name"
                  inputProps={{ maxLength: 100 }}
                  value={inputDataForm.values.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={inputDataForm.handleChange}
                  error={!!inputDataForm.errors.name}
                  helperText={inputDataForm.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  data-cy="dialog-input-field-alias-name"
                  label="Alias Name"
                  name="aliasName"
                  inputProps={{ maxLength: 10 }}
                  value={inputDataForm.values.aliasName}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={inputDataForm.handleChange}
                  error={!!inputDataForm.errors.aliasName}
                  helperText={inputDataForm.errors.aliasName}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  data-cy="dialog-input-field-description"
                  label="Description"
                  name="description"
                  inputProps={{ maxLength: 250 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={inputDataForm.values.description}
                  onChange={inputDataForm.handleChange}
                  error={!!inputDataForm.errors.description}
                  helperText={inputDataForm.errors.description}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormGroup style={{ paddingTop: '15px' }}>
                    <FormLabel
                      data-cy="dialog-business-types-label"
                      component="legend"
                    >
                      Business Types
                    </FormLabel>
                    <FormHelperText style={{ color: 'red' }}>
                      {inputDataForm.errors.businessTypeName}
                    </FormHelperText>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      {hardcodeData.getBusinessType().map((x) => {
                        return (
                          <FormControlLabel
                            data-cy={`dialog-checkbox-${x.id}`}
                            key={`checkbox_${x.id}`}
                            label={x.name}
                            control={
                              <Checkbox
                                name="businessTypeName"
                                onChange={(e) => {
                                  if (e.target.value === 'Buyer')
                                    inputDataForm.setFieldValue(
                                      'validateBuyerTypeName',
                                      !inputDataForm.values
                                        .validateBuyerTypeName
                                    )
                                  inputDataForm.handleChange(e)
                                }}
                                checked={inputDataForm.values.businessTypeName.includes(
                                  x.name
                                )}
                                value={x.name}
                              />
                            }
                          />
                        )
                      })}
                    </Box>
                  </FormGroup>
                </FormControl>
              </Grid>
              {inputDataForm.values.businessTypeName.find(
                (x) => x === 'Buyer'
              ) && (
                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormGroup
                      data-cy="dialog-buyer-type-label"
                      style={{ paddingTop: '12px' }}
                    >
                      <FormLabel component="legend">Buyer Type</FormLabel>
                      <FormHelperText style={{ color: 'red' }}>
                        {inputDataForm.errors.buyerTypeName}
                      </FormHelperText>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        <FormControlLabel
                          label="Active"
                          control={
                            <Checkbox
                              data-cy="dialog-buyer-type-active-checkbox"
                              name="buyerTypeName"
                              onChange={inputDataForm.handleChange}
                              value="Active"
                              checked={inputDataForm.values.buyerTypeName.includes(
                                'Active'
                              )}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Passive"
                          control={
                            <Checkbox
                              data-cy="dialog-buyer-type-passive-checkbox"
                              name="buyerTypeName"
                              onChange={inputDataForm.handleChange}
                              value="Passive"
                              checked={inputDataForm.values.buyerTypeName.includes(
                                'Passive'
                              )}
                            />
                          }
                        />
                      </Box>
                    </FormGroup>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setOpenEditDialog(false)
                    inputDataForm.resetForm({ values: initialValues })
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-cy="dialog-create-button"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!inputDataForm.dirty || !inputDataForm.isValid}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>

      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          title="Delete"
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                Would you like to delete this form?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => {
            confirmCallback()
            setOpenDeleteDialog(false)
          }}
        />
      )}
    </>
  )
}

export default FillableCommandCell
