/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import {
  GridCellProps,
  GridColumn as KendoGridColumn,
} from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { DocumentCommandCell } from 'src/components/GridCommandCell'
import { useQuery } from 'react-query'
import { BACKEND_URL, CONTROLPANEL_URL } from 'src/api/axios/api'
import hardcodeData from 'src/utils/hardcodeData'
import { State } from '@progress/kendo-data-query'
import { GetAccessToken } from 'src/api/axios/helper'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext, Can } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { Close } from '@material-ui/icons'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import Loader from 'src/components/Loader'
import DocumentQuestion from 'src/components/DocumentQuestion/DocumentQuestion'
import { DialogTitleStyled } from '../NewDocumentRegistration/style'

interface IDocument {
  categoryId: number | undefined
  name: string
  description: string
  aliasName: string
  isRequired: boolean
  isEnabled: boolean
  isLicense: boolean
  allowAudioFile: boolean
  businessTypeName: string[]
  validateBuyerTypeName: boolean
  buyerTypeName: string[]
  jurisdiction: number[]
  questions: number[]
  assetType: number[]
  includePastDocumentsDueDiligence: boolean
}

const Document: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [license, setLicense] = useState(false)
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const [data, setData] = useState<any[]>([])
  const [category, setCategory] = useState<any[]>([])
  const [jurisdiction, setJurisdiction] = useState<any[]>([])

  const [jurisdictionSelected, setJurisdictionSelected] = useState<any[]>([])

  const [assetTypes, setAssetTypes] = useState<any[]>([])
  const [assetTypesSelected, setAssetTypesSelected] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [, setQuestions] = useState<any[]>([])
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false)
  const [, setQuestionsSelected] = useState<any[]>([])

  const userToken = GetAccessToken()
  const { userPermissions } = useContext(AuthContext)
  const ability = useContext(AbilityContext)

  const [total, setTotal] = useState(0)
  const canManage =
    userPermissions.type === 'internal' &&
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageDocumentSetup,
      'any'
    )
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const getData = () => {
    axios({
      method: 'get',
      url: `${BACKEND_URL}/documents`,
      params: { pagination: encodeURIComponent(JSON.stringify(gridState)) },
      headers: {
        Authorization: userToken,
      },
    }).then((result: any) => {
      setData(result.data.documents)
      setTotal(result.data.total)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState])

  const { isLoading: loadingCategory } = useQuery({
    queryKey: ['getCategories'],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${BACKEND_URL}/category`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setCategory(result.data.categories)
      }),
    cacheTime: 0,
  })

  const { isLoading: loadingJurisdiction } = useQuery({
    queryKey: ['getJurisdiction'],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${BACKEND_URL}/jurisdiction`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setJurisdiction(result.data.jurisdictions)
      }),
    cacheTime: 0,
  })

  const { isLoading: loadingQuestion } = useQuery({
    queryKey: ['getQuestions'],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${BACKEND_URL}/question/GetAll`,
        params: { status: true },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setQuestions(result.data.questions)
      }),
    cacheTime: 0,
  })

  const { isLoading: loadingAssetType } = useQuery({
    queryKey: ['getAssetTypes'],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${CONTROLPANEL_URL}/client.getAssetTypes`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setAssetTypes(result.data)
      }),
    cacheTime: 0,
  })

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValues, setInitialValues] = useState<IDocument>({
    name: '',
    categoryId: undefined,
    description: '',
    isEnabled: false,
    aliasName: '',
    isRequired: false,
    isLicense: false,
    allowAudioFile: false,
    businessTypeName: [],
    buyerTypeName: [],
    validateBuyerTypeName: false,
    jurisdiction: [],
    questions: [],
    assetType: [],
    includePastDocumentsDueDiligence: false,
  })

  const inputDataFormSchema = Yup.object().shape({
    name: Yup.string().trim().required('Required'),
    categoryId: Yup.number().typeError('Required').required('Required'),
    description: Yup.string(),
    isEnabled: Yup.boolean().required('Required'),
    isRequired: Yup.boolean().required('Required'),
    isLicense: Yup.boolean().required('Required'),
    businessTypeName: Yup.array().min(1, 'Required'),
    validateBuyerTypeName: Yup.boolean(),
    buyerTypeName: Yup.array().when(
      'businessTypeName',
      (arrayData: string[], schema: any) =>
        arrayData.includes('Buyer') ? schema.min(1, 'Required') : schema
    ),
    jurisdiction: Yup.array().when([], {
      is: () => license === true,
      then: (schema: any) => {
        return license ? schema.min(1, 'Required') : schema
      },
    }),
  })

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const savedAssetTypes = assetTypes
        .filter((f) => values.assetType.includes(f.id))
        ?.map((c) => {
          return { id: c.id, name: c.name }
        })
      setLoading(true)
      const requestValues: any = {
        name: values.name || null,
        categoryId:
          typeof values.categoryId === 'number' ? values.categoryId : null,
        description: values.description || null,
        isEnabled: values.isEnabled,
        isRequired: values.isRequired,
        aliasName: values.aliasName || null,
        isLicense: license,
        businessTypeName: values.businessTypeName || null,
        buyerTypeName: values.buyerTypeName || null,
        jurisdiction: values.jurisdiction || null,
        question: values.questions || null,
        allowAudioFile: values.allowAudioFile,
        assetType: savedAssetTypes || null,
        includePastDocumentsDueDiligence:
          values.includePastDocumentsDueDiligence,
      }

      axios({
        method: 'post',
        url: `${BACKEND_URL}/documents`,
        data: requestValues,
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('New data added', notistackSucces)
          setOpenAddDialog(false)
          setLoading(false)
          getData()
        })
        .catch((error) => {
          setOpenAddDialog(false)
          enqueueSnackbar(error.response.data, notistackError)
          setLoading(false)
        })

      setSubmitting(false)
      inputDataForm.resetForm({ values: initialValues })
    },
  })

  const CommandCell = (props: GridCellProps) => (
    <DocumentCommandCell
      dataItem={props.dataItem}
      category={category}
      jurisdiction={jurisdiction}
      assetTypes={assetTypes}
      updateSuccededCallback={() => {
        axios({
          method: 'get',
          url: `${BACKEND_URL}/documents`,
          params: { pagination: gridState },
          headers: {
            Authorization: userToken,
          },
        }).then((result: any) => {
          setData(result.data.documents)
          setTotal(result.data.total)
          setLoading(false)
        })
      }}
      confirmCallback={() => {
        axios({
          method: 'delete',
          url: `${BACKEND_URL}/documents/${props.dataItem.id}`,
          headers: {
            Authorization: userToken,
          },
        })
          .then(() => {
            enqueueSnackbar('Data deleted', notistackSucces)
            getData()
          })
          .catch((error) => {
            enqueueSnackbar(error.response.data, notistackError)
            setLoading(false)
          })
      }}
    />
  )

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const getAvailableAssetTypes = () => {
    const available = assetTypes
      ?.filter(
        (item) =>
          !inputDataForm.values.assetType?.some((x: any) => x.id === item.id)
      )
      .map((x) => {
        return {
          id: x.id,
          name: x.name,
          displayName: `${x.name}`,
        }
      })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const renderAssetType = () => {
    const handleChange = (value: any) => {
      setAssetTypesSelected(value)
      inputDataForm.setFieldValue('assetType', value)
    }

    const assetData = assetTypes ? getAvailableAssetTypes() : []

    return (
      <MultipleSelectionDropDown
        id="input-assetType"
        label="Asset Type"
        data={assetData}
        disable={false}
        selectionState={assetTypesSelected}
        handleSelectionChange={handleChange}
        displayProperties={['name']}
      />
    )
  }

  const getAvailableJurisdictions = () => {
    const available = jurisdiction.map((x) => {
      return {
        id: x.id,
        name: x.name,
        type: x.type,
        displayName: `${x.type}: ${x.name}`,
      }
    })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const renderJurisdiction = () => {
    const handleChange = (value: any) => {
      setJurisdictionSelected(value)
      inputDataForm.setFieldValue('jurisdiction', value)
    }

    const jurisData = jurisdiction ? getAvailableJurisdictions() : []

    return (
      <MultipleSelectionDropDown
        id="demo-mutiple-checkbox"
        label="Jurisdiction"
        data={jurisData}
        disable={false}
        selectionState={jurisdictionSelected}
        handleSelectionChange={handleChange}
        separator=":"
        displayProperties={['type', 'name']}
      />
    )
  }

  return (
    <>
      <form onSubmit={inputDataForm.handleSubmit}>
        <Grid container style={{ height: '100%', width: '100%' }}>
          <Can
            do={
              PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageDocumentSetup
            }
            on="any"
          >
            <Grid container justifyContent="flex-end">
              <Box mr={2}>
                <Button
                  data-cy="create-license"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    inputDataForm.setFieldValue('businessTypeName', [], false)
                    setLicense(true)
                    setOpenAddDialog(true)
                  }}
                  disabled={!canManage}
                >
                  Create New License
                </Button>
              </Box>
              <Box>
                <Button
                  data-cy="create-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAssetTypesSelected([])
                    inputDataForm.setFieldValue('businessTypeName', [], false)
                    inputDataForm.setFieldValue('isLicense', false, false)
                    setLicense(false)
                    setOpenAddDialog(true)
                  }}
                  disabled={!canManage}
                >
                  Create New Document
                </Button>
              </Box>
            </Grid>
          </Can>
          <Grid container style={{ marginTop: '24px' }}>
            <Grid item>
              {(loading ||
                loadingQuestion ||
                loadingCategory ||
                loadingJurisdiction ||
                loadingAssetType) &&
                loadingPanel}
              <KendoGrid
                data={data || []}
                sortable
                style={{
                  height: 'auto',
                  maxHeight: window.innerHeight * 0.6,
                }}
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                total={total}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                }}
              >
                <KendoGridColumn
                  field="id"
                  title="ID"
                  width="60px"
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    return (
                      <td style={{ textAlign: 'center' }}>
                        {props.dataItem[field]}
                      </td>
                    )
                  }}
                />
                <KendoGridColumn field="categoryName" title="Category" />
                <KendoGridColumn field="name" title="Name" />

                <KendoGridColumn
                  field="businessTypeName"
                  title="Business Type"
                  sortable={false}
                />
                <KendoGridColumn
                  field="isEnabled"
                  title="Enabled"
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    return <td>{props.dataItem[field] ? 'Yes' : 'No'}</td>
                  }}
                />
                <KendoGridColumn
                  field="isRequired"
                  title="Required"
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    return <td>{props.dataItem[field] ? 'Yes' : 'No'}</td>
                  }}
                />
                <KendoGridColumn
                  field="isLicense"
                  title="Is License"
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    return <td>{props.dataItem[field] ? 'Yes' : 'No'}</td>
                  }}
                />
                <KendoGridColumn
                  field="allowAudioFile"
                  title="Audio File"
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    return <td>{props.dataItem[field] ? 'Yes' : 'No'}</td>
                  }}
                />
                <KendoGridColumn field="buyerTypeName" title="Buyer Type" />
                <KendoGridColumn
                  field="jurisdiction"
                  title="Jurisdiction"
                  sortable={false}
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    const jurisdictionDisplay = jurisdiction
                      .filter((x) => props.dataItem[field].includes(x.id))
                      .map((x) => `${x.type}: ${x.name}`)
                      .join(', ')

                    if (jurisdictionDisplay.length > 15)
                      return (
                        <Tooltip title={jurisdictionDisplay}>
                          <td>
                            <MoreHorizIcon />
                          </td>
                        </Tooltip>
                      )

                    return <td>{jurisdictionDisplay}</td>
                  }}
                />
                <KendoGridColumn
                  field="assetType"
                  title="Asset Type"
                  sortable={false}
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    const assetTypeDisplay = assetTypes
                      .filter((x) => props.dataItem[field].includes(x.id))
                      .map((x) => `${x.name}`)
                      .join(', ')

                    if (assetTypeDisplay.length > 15)
                      return (
                        <Tooltip title={assetTypeDisplay}>
                          <td>
                            <MoreHorizIcon />
                          </td>
                        </Tooltip>
                      )

                    return <td>{assetTypeDisplay}</td>
                  }}
                />
                {canManage && !loading && !loadingAssetType && (
                  <KendoGridColumn cell={CommandCell} />
                )}
              </KendoGrid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={openAddDialog}
          onClose={() => {
            setAssetTypesSelected([])
            setJurisdictionSelected([])
            setOpenAddDialog(false)
            inputDataForm.resetForm({ values: initialValues })
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitleStyled id="form-dialog-title">
            <Typography
              variant="h1"
              style={{ textAlign: 'center', color: 'white' }}
            >
              {license ? 'License' : 'Document'}
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => {
                setAssetTypesSelected([])
                setJurisdictionSelected([])
                inputDataForm.resetForm({ values: initialValues })
                setOpenAddDialog(false)
              }}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Close />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <form onSubmit={inputDataForm.handleSubmit}>
              {loading && <Loader />}
              <Grid container style={{ height: '100%', width: '100%' }}>
                <Grid
                  container
                  direction="column"
                  style={{ margin: '6px 6px 6px 6px', maxWidth: '200px' }}
                  spacing={3}
                >
                  <Grid item>
                    <TextField
                      data-cy="dialog-category-dropdown"
                      id="select-request-type"
                      select
                      label="Category"
                      style={{ width: '200px' }}
                      name="categoryId"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={inputDataForm.handleChange}
                      error={!!inputDataForm.errors.categoryId}
                      value={inputDataForm.values.categoryId}
                      helperText={inputDataForm.errors.categoryId}
                    >
                      <MenuItem value="">
                        <em>Select a category</em>
                      </MenuItem>
                      {category.map((dt: any) => {
                        return (
                          <MenuItem key={`${dt.name}`} value={dt.id}>
                            <Typography>{dt.name}</Typography>
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      data-cy="dialog-input-field-name"
                      label="Name"
                      name="name"
                      inputProps={{ maxLength: 100 }}
                      value={inputDataForm.values.name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={inputDataForm.handleChange}
                      error={!!inputDataForm.errors.name}
                      helperText={inputDataForm.errors.name}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      data-cy="dialog-input-field-alias-name"
                      label="Alias Name"
                      name="aliasName"
                      inputProps={{ maxLength: 10 }}
                      value={inputDataForm.values.aliasName}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={inputDataForm.handleChange}
                      error={!!inputDataForm.errors.aliasName}
                      helperText={inputDataForm.errors.aliasName}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      data-cy="dialog-input-field-description"
                      label="Description"
                      name="description"
                      inputProps={{ maxLength: 250 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={inputDataForm.values.description}
                      onChange={inputDataForm.handleChange}
                      error={!!inputDataForm.errors.description}
                      helperText={inputDataForm.errors.description}
                    />
                  </Grid>
                </Grid>
                <Grid>
                  <FormControl>
                    <FormGroup style={{ padding: '12px' }}>
                      <FormLabel
                        data-cy="dialog-business-types-label"
                        component="legend"
                      >
                        Business Types
                      </FormLabel>
                      <FormHelperText style={{ color: 'red' }}>
                        {inputDataForm.errors.businessTypeName}
                      </FormHelperText>

                      {license ? (
                        <RadioGroup
                          data-cy="dialog-radio-group"
                          key="radio-gruop"
                          aria-labelledby="radio-buttons-group"
                          name="radio-buttons-group"
                          value={inputDataForm.values.businessTypeName[0] || ''}
                          onChange={(e) => {
                            inputDataForm.values.businessTypeName.pop()
                            inputDataForm.values.businessTypeName.push(
                              e.target.value
                            )

                            if (e.target.value === 'Buyer')
                              inputDataForm.setFieldValue(
                                'validateBuyerTypeName',
                                !inputDataForm.values.validateBuyerTypeName
                              )

                            inputDataForm.handleChange(e)
                          }}
                        >
                          {hardcodeData.getBusinessType().map((x) => (
                            <FormControlLabel
                              data-cy={`dialog-radio-${x.id}`}
                              key={`radio${x.id}`}
                              value={x.name}
                              control={<Radio />}
                              label={x.name}
                            />
                          ))}
                        </RadioGroup>
                      ) : (
                        hardcodeData.getBusinessType().map((x) => {
                          return (
                            <FormControlLabel
                              data-cy={`dialog-checkbox-${x.id}`}
                              key={`checkbox_${x.id}`}
                              label={x.name}
                              control={
                                <Checkbox
                                  name="businessTypeName"
                                  onChange={(e) => {
                                    if (e.target.value === 'Buyer')
                                      inputDataForm.setFieldValue(
                                        'validateBuyerTypeName',
                                        !inputDataForm.values
                                          .validateBuyerTypeName
                                      )
                                    inputDataForm.handleChange(e)
                                  }}
                                  checked={inputDataForm.values.businessTypeName.includes(
                                    x.name
                                  )}
                                  value={x.name}
                                />
                              }
                            />
                          )
                        })
                      )}
                    </FormGroup>
                  </FormControl>
                </Grid>
                {inputDataForm.values.businessTypeName.find(
                  (x) => x === 'Buyer'
                ) && (
                  <Grid>
                    <FormGroup
                      data-cy="dialog-buyer-type-label"
                      style={{ padding: '12px' }}
                    >
                      <FormLabel component="legend">Buyer Type</FormLabel>
                      <FormHelperText style={{ color: 'red' }}>
                        {inputDataForm.errors.buyerTypeName}
                      </FormHelperText>
                      <FormControlLabel
                        label="Active"
                        control={
                          <Checkbox
                            data-cy="dialog-buyer-type-active-checkbox"
                            name="buyerTypeName"
                            onChange={inputDataForm.handleChange}
                            value="Active"
                            checked={inputDataForm.values.buyerTypeName.includes(
                              'Active'
                            )}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Passive"
                        control={
                          <Checkbox
                            data-cy="dialog-buyer-type-passive-checkbox"
                            name="buyerTypeName"
                            onChange={inputDataForm.handleChange}
                            value="Passive"
                            checked={inputDataForm.values.buyerTypeName.includes(
                              'Passive'
                            )}
                          />
                        }
                      />
                    </FormGroup>
                  </Grid>
                )}
                <Grid item style={{ paddingLeft: '20px' }}>
                  <Grid item>
                    <Typography component="div">
                      <Grid
                        data-cy="dialog-disable-enabled-selector"
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>Disabled</Grid>
                        <Grid item>
                          <Switch
                            checked={inputDataForm.values.isEnabled}
                            onChange={inputDataForm.handleChange}
                            name="isEnabled"
                          />
                        </Grid>
                        <Grid item>Enabled</Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography component="div">
                      <Grid
                        data-cy="dialog-required-not-required-selector"
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>Not Required</Grid>
                        <Grid item>
                          <Switch
                            checked={inputDataForm.values.isRequired}
                            onChange={inputDataForm.handleChange}
                            name="isRequired"
                          />
                        </Grid>
                        <Grid item>Required</Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  {(inputDataForm.values.businessTypeName.find(
                    (x) => x === 'Buyer'
                  ) ||
                    inputDataForm.values.businessTypeName.find(
                      (x) => x === 'Agency'
                    )) && (
                    <Grid item>
                      <Typography component="div">
                        <Grid
                          data-cy="dialog-allow-audio"
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>Allow Audio File</Grid>
                          <Grid item>
                            <Switch
                              checked={inputDataForm.values.allowAudioFile}
                              onChange={inputDataForm.handleChange}
                              name="allowAudioFile"
                            />
                          </Grid>
                          {inputDataForm.values.allowAudioFile && (
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  setQuestionsSelected([])
                                  setOpenQuestionDialog(true)
                                }}
                              >
                                Question
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Typography>
                    </Grid>
                  )}
                  {!license && (
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-cy="dialog-include-past-documents-checkbox"
                            checked={
                              inputDataForm.values
                                .includePastDocumentsDueDiligence
                            }
                            onChange={inputDataForm.handleChange}
                            name="includePastDocumentsDueDiligence"
                            color="primary"
                          />
                        }
                        label="Include past documents to due diligence package"
                      />
                    </Grid>
                  )}
                  {license && (
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={true}
                            data-cy="dialog-is-license-checkbox"
                            checked={license}
                            onChange={(e) => {
                              if (!license)
                                inputDataForm.setFieldValue('jurisdiction', [])

                              inputDataForm.handleChange(e)
                            }}
                            name="isLicense"
                            color="primary"
                          />
                        }
                        label="Is License"
                      />
                    </Grid>
                  )}

                  {license && <Grid item>{renderJurisdiction()}</Grid>}
                  {!license &&
                    inputDataForm.values.businessTypeName.find(
                      (x) => x === 'Seller'
                    ) && <Grid item>{renderAssetType()}</Grid>}
                </Grid>
                <Grid container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setAssetTypesSelected([])
                        setJurisdictionSelected([])
                        setOpenAddDialog(false)
                        inputDataForm.resetForm({ values: initialValues })
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-cy="dialog-create-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={!inputDataForm.dirty || !inputDataForm.isValid}
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openQuestionDialog}
          onClose={() => {
            setOpenQuestionDialog(false)
            inputDataForm.resetForm({ values: initialValues })
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitleStyled id="form-dialog-title">
            <Typography
              variant="h1"
              style={{ textAlign: 'center', color: 'white' }}
            >
              Question
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => {
                setOpenQuestionDialog(false)
                inputDataForm.resetForm({ values: initialValues })
              }}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Close />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <DocumentQuestion
              open={openQuestionDialog}
              close={() => setOpenQuestionDialog(false)}
              setQuestionsSelected={(values: any[]) => {
                inputDataForm.setFieldValue('questions', values)
                setQuestionsSelected(values)
              }}
            />
          </DialogContent>
        </Dialog>
      </form>
    </>
  )
}

export default Document
