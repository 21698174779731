import React from 'react'
import {
  formatDate,
  formatDateAndTimeLocal,
  getLocalDatetimer,
  getLocaleDateTimeString,
} from './date'
import { ConvertPostSaleRequestStatusToDisplay } from './nameConvertions'
import { numberToPercentage } from './numbers'
import { capitalizeAllText } from './text'

export const renderEscalated = (props: any) => {
  return <td>{props.dataItem[props.field] ? 'Yes' : 'No'}</td>
}

export const renderStatus = (props: any) => {
  return (
    <td>
      {ConvertPostSaleRequestStatusToDisplay(props.dataItem[props.field])}
    </td>
  )
}

export const renderTextCenter = (props: any) => {
  return <td style={{ textAlign: 'center' }}>{props.dataItem[props.field]}</td>
}

export const renderDate = (props: any) => {
  return (
    <td>
      {props.dataItem[props.field]
        ? formatDate(new Date(props.dataItem[props.field]))
        : props.dataItem[props.field]}
    </td>
  )
}

export const renderDateTime = (props: any) => {
  return (
    <td>
      {props.dataItem[props.field]
        ? formatDateAndTimeLocal(
            new Date(props.dataItem[props.field]),
            getLocaleDateTimeString()
          )
        : props.dataItem[props.field]}
    </td>
  )
}

const convertToJsonString = (value: string) => {
  let result = ''

  const jsonObject = JSON.parse(value)

  for (const key in jsonObject) {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonObject.hasOwnProperty(key)) {
      result += `${key}: ${jsonObject[key]} | `
    }
  }

  // Remove the trailing ' | ' and return the formatted string
  return result.slice(0, -3)
}

export const renderJson = (props: any) => {
  return <td>{convertToJsonString(props.dataItem[props.field])}</td>
}

export const renderNumber = (props: any) => {
  return <td style={{ textAlign: 'right' }}>{props.dataItem[props.field]}</td>
}

export const renderPercent = (props: any) => {
  return (
    <td style={{ textAlign: 'right' }}>
      {numberToPercentage(props.dataItem[props.field])}
    </td>
  )
}

export const renderCapitalize = (props: any) => {
  return <td>{capitalizeAllText(props.dataItem[props.field])}</td>
}

export const renderRemaining = (props: any) => {
  const { days, hours } = getLocalDatetimer(props.dataItem[props.field])
  return <td>{days >= 0 ? `(${days} Days, ${hours} Hours)` : ''}</td>
}
