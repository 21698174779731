import { Box, Button, Grid, MenuItem, TextField } from '@material-ui/core'
import React from 'react'
import * as Yup from 'yup'
import { useQuery, useQueryClient } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import axios from 'axios'
import { getUserBusiness } from 'src/api/operations/get/business'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { getLocaleDateString } from 'src/utils/date'
import Skeleton from '@material-ui/lab/Skeleton'
import Loader from '../Loader'

interface ICertification {
  userId: string
  businessId: string
  assignedUser: any
  emailBusiness: string
  close: () => void
}

interface IInitialValues {
  user: string
  startDate?: any
  endDate?: any
  reason: string
}

const AssignCertification: React.FC<ICertification> = ({
  userId,
  businessId,
  assignedUser,
  emailBusiness,
  close,
}: ICertification) => {
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const queryClient = useQueryClient()

  const currentDate = new Date()
  const nextDay = new Date(currentDate)
  nextDay.setDate(currentDate.getDate() + 1)

  const [initialValues] = React.useState<IInitialValues>({
    user: assignedUser ? assignedUser?.userId.toLowerCase() : '',
    startDate: assignedUser ? assignedUser?.startDateUtc : null,
    endDate: assignedUser ? assignedUser?.endDateUtc : null,
    reason: '',
  })

  const getUsersQuery = useQuery({
    queryKey: ['getUsersQuery', userId],
    queryFn: async () => {
      const result = await getUserBusiness(userId)
      const usersData = result.filter((t: any) => t.isCompliance)
      return usersData
    },
  })
  const usersData = userId && getUsersQuery?.data

  const inputDataFormSchema = Yup.object().shape({
    user: Yup.string().nullable().required('Required'),
    reason: Yup.string()
      .nullable()
      .when([], {
        is: () =>
          assignedUser?.userId !== null && assignedUser?.userId !== undefined,
        then: Yup.string().required('Required'),
      }),
    startDate: Yup.date()
      .nullable()
      .required('Required')
      .typeError('Invalid Date')
      .min(new Date(), 'Invalid Date'),
    endDate: Yup.date()
      .nullable()
      .required('Required')
      .typeError('Invalid Date')
      .min(Yup.ref('startDate'), 'Invalid Date'),
  })

  const userToken = GetAccessToken()

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        userId: values.user,
        userName:
          usersData?.find(
            (d: any) => d.id.toLowerCase() === values.user.toLowerCase()
          )?.name ?? '',
        startDateUtc: values.startDate ? new Date(values.startDate) : null,
        endDateUtc: values.endDate ? new Date(values.endDate) : null,
        businessId,
        reason: values.reason,
        emailAddress: emailBusiness,
      }
      axios({
        method: 'put',
        url: `${BACKEND_URL}/Certification/assign`,
        data: requestValues,
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('User assigned to certification', notistackSucces)
        })
        .catch((error) => {
          enqueueSnackbar(error.response.data, notistackError)
        })
        .finally(() => {
          queryClient.refetchQueries({
            queryKey: 'GetByBusinessId',
          })
          close()
        })
    },
  })

  return (
    <form onSubmit={inputDataForm.handleSubmit}>
      <>
        {inputDataForm.isSubmitting && <Loader />}

        <Box flexDirection="row">
          <Grid
            container
            direction="row"
            style={{ margin: '6px 6px 6px 6px', width: '98%' }}
            spacing={3}
          >
            <Grid item xs={12} md={4}>
              {getUsersQuery.isLoading ? (
                <Skeleton variant="rect" width="100%" height={50} />
              ) : (
                <TextField
                  data-cy="assign-certification-user-dropdown"
                  id="assign-certification-user-dropdown"
                  select
                  label="User"
                  style={{ width: '100%' }}
                  name="user"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={inputDataForm.handleChange}
                  value={inputDataForm.values.user || null}
                >
                  <MenuItem value="">
                    <em>Select an user</em>
                  </MenuItem>
                  {usersData &&
                    usersData?.map((u: any) => (
                      <MenuItem
                        value={u.id.toLowerCase()}
                        key={u.id.toLowerCase()}
                      >
                        {u.name}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                format={getLocaleDateString()}
                id="startDate"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Start Date"
                name="startDate"
                onChange={(date: any) => {
                  inputDataForm.setFieldValue('startDate', date, true)
                }}
                minDate={nextDay}
                value={inputDataForm.values.startDate}
                error={!!inputDataForm.errors.startDate}
                helperText={<>{inputDataForm.errors.startDate}</>}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                format={getLocaleDateString()}
                id="endDate"
                InputLabelProps={{
                  shrink: true,
                }}
                label="End Date"
                minDate={inputDataForm.values.startDate || nextDay}
                name="endDate"
                value={inputDataForm.values.endDate}
                error={!!inputDataForm.errors.endDate}
                helperText={<>{inputDataForm.errors.endDate}</>}
                onChange={(date: any) => {
                  inputDataForm.setFieldValue('endDate', date, true)
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                disabled={
                  assignedUser?.userId === null ||
                  assignedUser?.userId === undefined
                }
                variant="outlined"
                fullWidth
                multiline={true}
                minRows={3}
                data-cy="dialog-input-field-reason"
                label="Reason"
                name="reason"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 300,
                }}
                onChange={inputDataForm.handleChange}
                value={inputDataForm.values.reason || null}
                error={!!inputDataForm.errors.reason}
                helperText={inputDataForm.errors.reason}
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          mt={5}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Button
            style={{ marginRight: 5 }}
            data-cy="cancel-assign-button"
            variant="outlined"
            color="secondary"
            onClick={() => close()}
          >
            Cancel
          </Button>
          <Button
            data-cy="assign-button"
            variant="contained"
            color="primary"
            type="submit"
            disabled={
              !(inputDataForm.values.user && inputDataForm.values.startDate) ||
              inputDataForm.isSubmitting ||
              !inputDataForm.isValid
            }
          >
            Assign
          </Button>
        </Box>
      </>
    </form>
  )
}

export default AssignCertification
