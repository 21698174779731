import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  AssignmentInd,
  AssignmentTurnedIn,
  Check,
  Clear,
  Close,
  Comment,
  ErrorRounded,
  FileCopy,
  FilterList,
  FolderSpecial,
  GetApp,
  PersonAdd,
  PlayCircleFilledOutlined,
  Warning,
} from '@material-ui/icons'
import DescriptionIcon from '@material-ui/icons/Description'
import SendIcon from '@material-ui/icons/Send'
import Skeleton from '@material-ui/lab/Skeleton'
import axios from 'axios'

import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { Query, useMutation, useQuery, useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { ICategory, ICertificationPeriod } from 'src/api/models'
import {
  getBusinessesQuery,
  getBuyerQuery,
  getUserBusiness,
} from 'src/api/operations/get/business'
import { CircularProgressWithLabel } from 'src/components/CircularProgressBarWithLabel'
import { ConfirmDialog } from 'src/components/Dialogs'
import { SELLER, USER_ID } from 'src/configs/AuthService'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AuthContext } from 'src/context/AuthenticationContext'
import { BusinessContext } from 'src/context/BusinessContext'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'
import { AssignCertification } from 'src/components/AssignCertification'
import { CertificationComment } from 'src/components/CertificationComment'
import Loader from 'src/components/Loader'
import hardcodeData from 'src/utils/hardcodeData'

import { CategoriesTabs } from 'src/components/CategoryTabs'
import { DocumentCardMain } from 'src/components/DocumentCardMain'
import { CloneAllDocuments } from 'src/components/CloneAllDocuments'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { DialogTitleStyled, WidthFixedTooltip } from './style'

interface User {
  id: string
  status: string
  name: string
}

interface IFilter {
  filter: string[]
}
const DocumentRegistrationMain: React.FC = () => {
  const [selectedBusinessStatus, setSelectedBusinessStatus] = useState<any>()

  const [selectedBusiness, setSelectedBusiness] = useState<any>()
  const [selectedCertificationPeriod, setSelectedCertificationPeriod] =
    useState<ICertificationPeriod>({ id: null, certificationDate: 'Current' })
  const [certificationDate, setCertificationDate] = React.useState<string>('')
  const [openCompleteCertificationDialog, setOpenCompleteCertificationDialog] =
    React.useState(false)

  const [openStartCertificationDialog, setOpenStartCertificationDialog] =
    React.useState(false)

  const [certificationStatus, setCertificationStatus] = useState('')
  const [reasonStatus, setReasonStatus] = useState('')
  const [businessStatus, setBusinessStatus] = useState('')

  const [openAssignCertificationUser, setOpenAssignCertificationUser] =
    React.useState(false)

  const [
    openCertificationBusinessComments,
    setOpenCertificationBusinessComments,
  ] = React.useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const notistackSuccess = notistackOptions('success')
  const [userSelected, setUserSelected] = useState<any>()
  const notistackError = notistackOptions('error')

  const [assigningCategory, setAssigningCategory] = useState<boolean>(false)
  const emptyArray = [1, 2]
  const { user } = useContext(AuthContext)
  const [userList, setUserList] = useState<User[] | null>(null)
  const [openAssignCategoryDialog, setOpenAssignCategoryDialog] =
    useState<boolean>(false)
  const [openCloneDialog, setOpenCloneDialog] = useState<boolean>(false)
  const { profileClient } = useContext(AuthContext)
  const { userPermissions } = useContext(AuthContext)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const userToken = GetAccessToken()
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const { search } = useLocation()
  const queryString = new URLSearchParams(search)
  const categoryId = queryString.get('category')
  const userId = user.profile[USER_ID]
  const ability = useContext(AbilityContext)

  useEffect(() => {
    const d = new Date()
    const certDate = `${d.getMonth() + 1}/${d.getFullYear()}`
    setCertificationDate(certDate)
  }, [])

  const getDueDiligence = useQuery({
    queryKey: ['getDueDiligence', selectedBusiness?.id],
    queryFn: async () => {
      return axios({
        method: 'GET',
        url: `${BACKEND_URL}/documents/GetDueDiligenceDeck`,
        params: {
          businessId: selectedBusiness.id,
          certificationPeriodId: selectedCertificationPeriod.id,
          certificationDate: selectedCertificationPeriod.certificationDate,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    enabled: Boolean(selectedBusiness?.id),
  })

  const dueDiligenceUri = getDueDiligence?.data?.data

  const handleDownloadDueDiligence = () => {
    window.location.href = dueDiligenceUri || ''
  }

  const businessesQuery = useQuery({
    queryKey: ['businesses'],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getBusinessesQuery(
            profileClient ? profileClient.Id : null
          )}`,
        },
      }).then((result: any) => {
        const businessesData = result.data.data.getBusinesses.businesses
        return businessesData
      })
    },
  })

  const getUsersQuery = useQuery({
    enabled: !isInternal,
    queryKey: ['getUsersQuery', selectedBusiness?.id],
    queryFn: async () => {
      const result = await getUserBusiness(userId)
      const usersData = result
      return usersData
    },
  })

  const usersData = !isInternal && userId && getUsersQuery?.data

  const businessesData = businessesQuery?.data

  useEffect(() => {
    if (usersData) {
      setUserList(usersData)
    }
  }, [usersData])

  useEffect(() => {
    if (businessesData) setSelectedBusiness(businessesData[0])
  }, [businessesData])

  useEffect(() => {
    if (isInternal && businessesData) {
      if (selectedBusinessStatus !== null && selectedBusinessStatus) {
        const filteredBusiness = businessesData.filter(
          (f: any) => f.status === selectedBusinessStatus.value
        )

        if (filteredBusiness != null && filteredBusiness.length > 0) {
          handleSelectedBusiness(filteredBusiness[0])
        } else {
          handleSelectedBusiness(null)
        }
      } else {
        handleSelectedBusiness(businessesData[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessStatus, businessesData])

  const handleSelectedBusiness = (business: any) => {
    setSelectedBusiness(business)

    // Removing all queries from the cache when a business change
    // so the data is loaded again for the selected business
    queryClient.removeQueries({
      predicate: (cachedQuery: Query) => {
        if (cachedQuery.queryKey[0] === 'businesses') return false

        return true
      },
    })

    setSelectedCertificationPeriod({ id: null, certificationDate: 'Current' })
  }

  const progressBarQuery = useQuery({
    enabled: !!selectedBusiness && !!selectedCertificationPeriod,
    queryKey: [
      'uploadProgressPercent',
      selectedBusiness,
      selectedCertificationPeriod.certificationDate,
    ],
    queryFn: async () => {
      if (selectedBusiness) {
        return axios({
          method: 'get',
          url: `${BACKEND_URL}/BusinessUpload/CheckUploadProgress`,
          headers: {
            Authorization: userToken,
          },
          params: {
            businessId: selectedBusiness?.id,
            businessType: selectedBusiness?.type,
            buyerType: selectedBusiness?.buyerType,
            certificationPeriodId: selectedCertificationPeriod.id,
          },
        }).then((result: any) => {
          return result.data
        })
      }
      return null
    },
  })

  const progressBarPercentValue = progressBarQuery.data

  const uploadedProgressBarQuery = useQuery({
    enabled: !!selectedBusiness && !!selectedCertificationPeriod,
    queryKey: [
      'uploadedProgressPercent',
      selectedBusiness,
      selectedCertificationPeriod.certificationDate,
    ],
    queryFn: async () => {
      if (selectedBusiness) {
        return axios({
          method: 'get',
          url: `${BACKEND_URL}/BusinessUpload/CheckUploadedProgress`,
          headers: {
            Authorization: userToken,
          },
          params: {
            businessId: selectedBusiness?.id,
            businessType: selectedBusiness?.type,
            buyerType: selectedBusiness?.buyerType,
            certificationPeriodId: selectedCertificationPeriod.id,
          },
        }).then((result: any) => {
          return result.data
        })
      }
      return null
    },
  })

  const uploadedProgressBarPercentValue = uploadedProgressBarQuery.data

  const assignedUserQuery = useQuery({
    enabled: !!selectedBusiness && !!selectedCertificationPeriod,
    queryKey: [
      'GetByBusinessId',
      selectedBusiness,
      selectedCertificationPeriod.certificationDate,
    ],
    queryFn: async () => {
      if (selectedBusiness) {
        return axios({
          method: 'get',
          url: `${BACKEND_URL}/Certification/GetByBusinessId/${selectedBusiness?.id}`,
          headers: {
            Authorization: userToken,
          },
        }).then((result: any) => {
          return result.data
        })
      }
      return null
    },
  })

  const assignedUserCertification = assignedUserQuery.data

  const certificationStatusQuery = useQuery({
    enabled: !!selectedBusiness && !!certificationDate,
    queryKey: ['certificationStatus', selectedBusiness, certificationDate],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/actions/certificationdone/${selectedBusiness.id}`,
        params: {
          certificationDate,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const handleUserSelected = (selectedUser: any) => {
    setUserSelected(selectedUser)
    setOpenAssignCategoryDialog(true)
    setAnchorEl(null)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorFilter, setAnchorFilter] = React.useState<null | HTMLElement>(
    null
  )

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorFilter(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setAnchorFilter(null)
  }

  const handleAssignCategoryClose = () => {
    setUserSelected(undefined)
    setOpenAssignCategoryDialog(false)
  }

  const buyerTypeQuery = useQuery({
    enabled: !!selectedBusiness && selectedBusiness.type === 'Buyer',
    queryKey: ['buyerType', selectedBusiness],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getBuyerQuery(selectedBusiness.id)}`,
        },
      }).then((result: any) => {
        return result.data.data.getBuyer.buyerType
      })
    },
  })

  const categoryQuery = useQuery({
    enabled: !!selectedBusiness && !buyerTypeQuery.isLoading,
    queryKey: ['categoryType', selectedBusiness],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/category`,
        headers: {
          Authorization: userToken,
        },
        params: {
          isEnabled: true,
          businessType: selectedBusiness.type,
          buyerType: buyerTypeQuery ? buyerTypeQuery.data : '',
        },
      }).then((result: any) => {
        return result.data.categories
      })
    },
  })

  const handleAssignCategory = () => {
    assignUserDocuments.mutate()
  }

  const handleStartCertification = () => {
    startCertification.mutate()
  }
  const categories: ICategory[] = categoryQuery.data
  const categorySelected = categories?.find(
    (x) => x.id.toString() === categoryId
  )

  const startCertification = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/certification/startCertification`,
        params: {
          businessId: selectedBusiness.id,
          emailAddress: selectedBusiness.emailAddress,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('Certification started.', notistackSuccess)
          setOpenStartCertificationDialog(false)
          queryClient.refetchQueries('GetByBusinessId')
        })
        .catch((error: any) => {
          enqueueSnackbar(error.response.data, notistackError)
          setOpenStartCertificationDialog(false)
        })
    },
    onError: () => {
      enqueueSnackbar('Error on starting certification', notistackError)
    },
  })

  const readOnly =
    ['Terminated - Out of Business', 'Terminated'].includes(
      selectedBusiness?.status
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ViewAccess,
      'any'
    )

  const assignUserDocuments = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/userDocument`,
        params: {
          userId: userSelected.id,
          categoryId,
          businessId: selectedBusiness.id,
        },
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('Documents assigned to user', notistackSuccess)
          setOpenAssignCategoryDialog(false)
          setAssigningCategory(false)
          setUserSelected(undefined)
          queryClient.refetchQueries('documentsRequired')
          queryClient.refetchQueries('getAssignedUsers')
        })
        .catch((error: any) => {
          enqueueSnackbar(error.response.data, notistackError)
          setOpenAssignCategoryDialog(false)
          setAssigningCategory(false)
          setUserSelected(undefined)
        })
    },
    onError: () => {
      enqueueSnackbar('Error on assigning document to user', notistackError)
    },
  })

  const generateDueDiligenceMutation = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/documents/GenerateDueDiligenceDeck`,
        params: {
          businessId: selectedBusiness.id,
          certificationPeriodId: selectedCertificationPeriod.id,
          certificationDate: selectedCertificationPeriod.certificationDate,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        if (result.data !== null && result.data !== '') {
          window.location.href = result.data
          queryClient.refetchQueries('getDueDiligence')
        } else
          enqueueSnackbar(
            'There is no information to generate due diligence deck',
            notistackError
          )
      })
    },
    onError: () => {
      enqueueSnackbar('Error on generating due diligence deck', notistackError)
    },
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCloseAssignUser = () => {
    setOpenAssignCertificationUser(false)
  }

  const handleCloseComments = () => {
    setOpenCertificationBusinessComments(false)
  }

  const generateMissingDocumentsReportMutation = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/documents/GenerateMissingDocumentsReport`,
        headers: {
          Authorization: userToken,
        },
        params: {
          businessId: selectedBusiness.id,
          businessType: selectedBusiness.type,
          buyerType: selectedBusiness.buyerType,
        },
      }).then((result: any) => {
        window.location.href = result.data
      })
    },
    onError: () => {
      enqueueSnackbar('Error', notistackError)
    },
  })

  const generateReportAndNotifyMutation = useMutation({
    mutationFn: async () => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/documents/GenerateMissingDocumentsReport`,
        params: {
          businessId: selectedBusiness.id,
          businessType: selectedBusiness.type,
          sendNotification: true,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: () => {
      enqueueSnackbar('Report sent', notistackSuccess)
    },
    onError: () => {
      enqueueSnackbar('Error', notistackError)
    },
  })

  const handleGenerateDueDiligence = () => {
    generateDueDiligenceMutation.mutate()
  }

  const handleGenerateMissingItemsReport = () => {
    generateMissingDocumentsReportMutation.mutate()
  }

  const handleGenerateReportAndNotify = () => {
    generateReportAndNotifyMutation.mutate()
  }

  const completeCertificationMutation = useMutation({
    mutationFn: async () => {
      await axios({
        method: 'post',
        url: `${BACKEND_URL}/actions/CompleteCertification`,
        data: {
          businessId: selectedBusiness.id,
          certificationDate,
          status: certificationStatus,
          reason: certificationStatus === 'Failed' ? reasonStatus : null,
          businessStatus:
            certificationStatus === 'Failed' ? businessStatus : null,
          emailAddress: selectedBusiness.emailAddress,
          name: selectedBusiness.name,
        },
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: () => {
      queryClient.refetchQueries()
      enqueueSnackbar(
        `Certification has been completed. (${certificationStatus})`,
        notistackSuccess
      )
      setOpenCompleteCertificationDialog(false)
    },
    onError: () => {
      enqueueSnackbar('Error on completing certification', notistackError)
    },
  })

  const getComplianceUsers = useQuery({
    enabled: assignedUserCertification !== undefined,
    queryKey: ['getComplianceUsers', userId],
    queryFn: async () => {
      const result = await getUserBusiness(userId)
      const complianceUsersData = result.filter(
        (t: any) => t.id.toLowerCase() === assignedUserCertification.userId
      )
      return complianceUsersData
    },
  })
  const complianceUsersData = userId && getComplianceUsers?.data

  const handleCompleteCertificationClick = () => {
    completeCertificationMutation.mutate()
    setReasonStatus('')
    setCertificationStatus('')
    setBusinessStatus('')
  }

  const getCertificationQuery = useQuery({
    enabled: !!selectedBusiness,
    queryKey: ['getCertificationQuery', selectedBusiness],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/actions/certificationperiod/${selectedBusiness.id}`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const certificationPeriodsData: ICertificationPeriod[] =
    getCertificationQuery?.data

  const handleCertificationPeriodChange = (
    certificationPeriod: ICertificationPeriod
  ) => {
    setSelectedCertificationPeriod(certificationPeriod)
    // Removing all queries from the cache when a business change
    // so the data is loaded again for the selected business
    queryClient.removeQueries({
      predicate: (cachedQuery: Query) => {
        if (
          cachedQuery.queryKey[0] === 'businesses' ||
          cachedQuery.queryKey[0] === 'getCertificationQuery'
        )
          return false

        return true
      },
    })
  }

  const [filters, setFilters] = useState<any[]>([])

  const [initialValues] = useState<IFilter>({
    filter: [],
  })

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: null,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false)
    },
  })

  const handleApplyFilter = () => {
    setFilters(inputDataForm.values.filter)
    setAnchorFilter(null)
  }

  const handleClearFilter = () => {
    if (inputDataForm.values.filter.length > 0) {
      inputDataForm.setFieldValue('filter', [], false)
      setFilters([])
      queryClient.removeQueries('documentsRequired')
      queryClient.refetchQueries('documentsRequired')
    }
    setAnchorFilter(null)
  }

  const handleFilterChange = (e: any) => {
    if (inputDataForm.values.filter.includes(e)) {
      inputDataForm.setFieldValue(
        'filter',
        inputDataForm.values.filter.filter((f) => f !== e),
        false
      )
    } else {
      inputDataForm.setFieldValue(
        'filter',
        inputDataForm.values.filter.concat(e),
        false
      )
    }
  }

  const disableUI =
    completeCertificationMutation.isLoading ||
    getCertificationQuery.isLoading ||
    businessesQuery.isLoading ||
    progressBarQuery.isLoading ||
    uploadedProgressBarQuery.isLoading ||
    certificationStatusQuery.isLoading ||
    buyerTypeQuery.isLoading ||
    generateDueDiligenceMutation.isLoading ||
    generateReportAndNotifyMutation.isLoading ||
    generateMissingDocumentsReportMutation.isLoading ||
    getUsersQuery.isLoading ||
    assignUserDocuments.isLoading ||
    getDueDiligence.isLoading ||
    assignedUserQuery.isLoading ||
    getComplianceUsers.isLoading

  const noCategoriesRegistered =
    categories !== undefined && categories.length === 0

  const getAvailableBusinesses = () => {
    if (
      isInternal &&
      selectedBusinessStatus &&
      selectedBusinessStatus !== null
    ) {
      return businessesData.filter(
        (f: any) => f.status === selectedBusinessStatus.value
      )
    }

    return businessesData
  }
  const availableBusinesses =
    !businessesQuery.isLoading && getAvailableBusinesses()

  return (
    <>
      <CertificationPeriodContext.Provider value={selectedCertificationPeriod}>
        <Box padding={4} mt={4}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="subtitle1">Document Registration</Typography>
            </Grid>
            <Box
              style={{ width: '100%' }}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                {isInternal && (
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      verticalAlign: 'center',
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <TextField
                        select
                        id="select-business-status"
                        value={selectedBusinessStatus?.value ?? ''}
                        fullWidth
                        label="Business Status"
                        style={{ minWidth: '250px' }}
                      >
                        <MenuItem
                          value=""
                          onClick={() => {
                            setSelectedBusinessStatus(null)
                          }}
                        >
                          <em>Select business status</em>
                        </MenuItem>

                        {hardcodeData.getBusinessStatus().map((s: any) => {
                          return (
                            <MenuItem
                              key={s.value}
                              value={s.value}
                              onClick={() => {
                                setSelectedBusinessStatus(s)
                              }}
                            >
                              {`${s.label}`}
                            </MenuItem>
                          )
                        })}
                      </TextField>
                    </Box>
                  </Grid>
                )}

                {!businessesQuery.isLoading ? (
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      verticalAlign: 'center',
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Box
                        ml={3}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        {businessesQuery?.data?.length > 1 ? (
                          <TextField
                            select
                            id="select-business"
                            value={selectedBusiness?.id ?? ''}
                            fullWidth
                            label="Business"
                            style={{ minWidth: '250px' }}
                          >
                            <MenuItem value="" disabled={true}>
                              <em>Select business</em>
                            </MenuItem>

                            {availableBusinesses &&
                              availableBusinesses?.map((business: any) => {
                                return (
                                  <MenuItem
                                    key={business.id}
                                    value={business.id}
                                    onClick={() => {
                                      handleSelectedBusiness(business)
                                    }}
                                  >
                                    <Box>
                                      {[
                                        'Terminated - Out of Business',
                                        'Terminated',
                                      ].includes(business.status) && (
                                        <IconButton
                                          size="small"
                                          aria-label="settings"
                                        >
                                          <Tooltip title={business.status}>
                                            <ErrorRounded
                                              fontSize="small"
                                              style={{ color: '#FFA500' }}
                                            />
                                          </Tooltip>
                                        </IconButton>
                                      )}
                                      {`${business.name} - ${business.type}`}
                                    </Box>
                                  </MenuItem>
                                )
                              })}
                          </TextField>
                        ) : (
                          <Typography
                            id="select-business"
                            variant="h2"
                            style={{ fontWeight: 'bold' }}
                          >
                            {selectedBusiness?.name ?? ''}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  <Skeleton variant="rect" width={250} />
                )}
                {!(
                  getCertificationQuery.isLoading && businessesQuery.isLoading
                ) ? (
                  <Grid
                    item
                    style={{
                      marginLeft: 10,
                      display: 'flex',
                      alignItems: 'center',
                      verticalAlign: 'center',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        {availableBusinesses &&
                          availableBusinesses.length > 0 && (
                            <TextField
                              select
                              id="select-certification-date"
                              value={
                                selectedCertificationPeriod.certificationDate
                              }
                              style={{ minWidth: '115px' }}
                              label="Certification Period"
                            >
                              <MenuItem
                                value="Current"
                                onClick={() => {
                                  handleCertificationPeriodChange({
                                    id: null,
                                    certificationDate: 'Current',
                                  })
                                }}
                              >
                                Current
                              </MenuItem>

                              {certificationPeriodsData &&
                                certificationPeriodsData?.map(
                                  (item: ICertificationPeriod) => {
                                    return (
                                      <MenuItem
                                        key={item.id}
                                        value={item.certificationDate}
                                        onClick={() => {
                                          handleCertificationPeriodChange(item)
                                        }}
                                      >
                                        {item.certificationDate}
                                      </MenuItem>
                                    )
                                  }
                                )}
                            </TextField>
                          )}
                      </Box>
                    </Box>
                  </Grid>
                ) : (
                  <Skeleton variant="rect" width={250} />
                )}
                <Grid
                  item
                  style={{
                    width: '50px',
                    height: '50px',
                    marginLeft: 10,
                    display: 'flex',
                    alignItems: 'center',
                    verticalAlign: 'center',
                  }}
                >
                  {!uploadedProgressBarQuery.isLoading &&
                  !isNaN(uploadedProgressBarPercentValue) ? (
                    <CircularProgressWithLabel
                      progressValue={uploadedProgressBarPercentValue}
                      title="Percentage of required documents uploaded."
                      color="#5398dd"
                    />
                  ) : (
                    availableBusinesses &&
                    availableBusinesses.length > 0 && (
                      <Skeleton variant="circle" height={35} width={35} />
                    )
                  )}
                </Grid>

                <Divider
                  orientation="vertical"
                  variant="middle"
                  style={{ height: '40px' }}
                  flexItem
                />
                <Grid
                  item
                  style={{
                    width: '50px',
                    height: '50px',
                    marginLeft: 10,
                    display: 'flex',
                    alignItems: 'center',
                    verticalAlign: 'center',
                  }}
                >
                  {!progressBarQuery.isLoading &&
                  !isNaN(progressBarPercentValue) ? (
                    <CircularProgressWithLabel
                      progressValue={progressBarPercentValue}
                      title="Percentage of certification completed."
                      color="green"
                    />
                  ) : (
                    availableBusinesses &&
                    availableBusinesses.length > 0 && (
                      <Skeleton variant="circle" height={35} width={35} />
                    )
                  )}
                </Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    verticalAlign: 'center',
                  }}
                >
                  {isInternal &&
                    (businessesQuery.isLoading ||
                      availableBusinesses.length > 0) && (
                      <Tooltip title="Generate due diligence deck">
                        <span>
                          <IconButton
                            onClick={handleGenerateDueDiligence}
                            color="primary"
                            aria-label="Generate due diligence deck"
                            disabled={
                              disableUI ||
                              businessesQuery.isLoading ||
                              progressBarQuery.isLoading ||
                              readOnly
                            }
                          >
                            {businessesQuery.isLoading ? (
                              <Skeleton>
                                <FolderSpecial />
                              </Skeleton>
                            ) : (
                              <FolderSpecial />
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}

                  {isInternal &&
                    dueDiligenceUri &&
                    dueDiligenceUri !== null &&
                    dueDiligenceUri !== '' &&
                    (businessesQuery.isLoading ||
                      availableBusinesses.length > 0) && (
                      <Tooltip title="Download due diligence deck">
                        <span>
                          <IconButton
                            onClick={handleDownloadDueDiligence}
                            color="primary"
                            aria-label="Download due diligence deck"
                            disabled={
                              disableUI || getDueDiligence.isLoading || readOnly
                            }
                          >
                            {getDueDiligence.isLoading ? (
                              <Skeleton>
                                <GetApp />
                              </Skeleton>
                            ) : (
                              <GetApp />
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}

                  {selectedCertificationPeriod.id === null &&
                    (businessesQuery.isLoading ||
                      availableBusinesses.length > 0) && (
                      <>
                        <Tooltip title="Generate and download the missing items report">
                          <span>
                            <IconButton
                              onClick={handleGenerateMissingItemsReport}
                              color="primary"
                              aria-label="Generate and download the missing items report"
                              disabled={
                                disableUI ||
                                businessesQuery.isLoading ||
                                progressBarQuery.isLoading ||
                                readOnly
                              }
                            >
                              {businessesQuery.isLoading ? (
                                <Skeleton>
                                  <DescriptionIcon />
                                </Skeleton>
                              ) : (
                                <DescriptionIcon />
                              )}
                            </IconButton>
                          </span>
                        </Tooltip>

                        {(businessesQuery.isLoading ||
                          availableBusinesses.length > 0) && (
                          <Tooltip title="Send missing items report">
                            <span>
                              <IconButton
                                onClick={handleGenerateReportAndNotify}
                                color="primary"
                                aria-label="Send missing items report"
                                disabled={
                                  disableUI ||
                                  businessesQuery.isLoading ||
                                  progressBarQuery.isLoading ||
                                  readOnly
                                }
                              >
                                {businessesQuery.isLoading ? (
                                  <Skeleton>
                                    <SendIcon />
                                  </Skeleton>
                                ) : (
                                  <SendIcon />
                                )}
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        {isInternal &&
                          (businessesQuery.isLoading ||
                            availableBusinesses.length > 0) && (
                            <WidthFixedTooltip
                              title={
                                certificationStatusQuery.data
                                  ? `The certification for the ${certificationDate} period has been completed`
                                  : 'Complete the certification period'
                              }
                            >
                              <span>
                                <IconButton
                                  onClick={
                                    () =>
                                      setOpenCompleteCertificationDialog(true)
                                    // eslint-disable-next-line react/jsx-curly-newline
                                  }
                                  color="primary"
                                  aria-label="Complete the certification period"
                                  disabled={
                                    disableUI ||
                                    certificationStatusQuery.data ||
                                    businessesQuery.isLoading ||
                                    progressBarQuery.isLoading ||
                                    readOnly
                                  }
                                >
                                  {businessesQuery.isLoading ? (
                                    <Skeleton>
                                      <AssignmentTurnedIn />
                                    </Skeleton>
                                  ) : (
                                    <AssignmentTurnedIn />
                                  )}
                                </IconButton>
                              </span>
                            </WidthFixedTooltip>
                          )}

                        {isInternal &&
                          (businessesQuery.isLoading ||
                            availableBusinesses.length > 0) && (
                            <Tooltip
                              title={
                                assignedUserQuery.isLoading ||
                                !assignedUserCertification ? (
                                  <> Assign compliance analyst</>
                                ) : (
                                  <>
                                    <>{`Certification Assign to ${assignedUserCertification.userName}. `}</>
                                    <br />
                                    <>Click here to change.</>
                                  </>
                                )
                              }
                            >
                              <span>
                                <IconButton
                                  onClick={
                                    () => setOpenAssignCertificationUser(true)
                                    // eslint-disable-next-line react/jsx-curly-newline
                                  }
                                  color="primary"
                                  aria-label="Assign certification to a compliance analyst"
                                  disabled={
                                    disableUI ||
                                    getUsersQuery.isLoading ||
                                    readOnly
                                  }
                                >
                                  {businessesQuery.isLoading ? (
                                    <Skeleton>
                                      <PersonAdd />
                                    </Skeleton>
                                  ) : (
                                    <PersonAdd />
                                  )}
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                      </>
                    )}
                  {isInternal &&
                    (businessesQuery.isLoading ||
                      availableBusinesses.length > 0) && (
                      <Tooltip title="Certification history">
                        <span>
                          <IconButton
                            onClick={
                              () => setOpenCertificationBusinessComments(true)
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                            color="primary"
                            aria-label="Certification history"
                            disabled={disableUI}
                          >
                            {businessesQuery.isLoading ? (
                              <Skeleton>
                                <Comment />
                              </Skeleton>
                            ) : (
                              <Comment />
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  {complianceUsersData?.length > 0 &&
                    !assignedUserQuery.isLoading &&
                    assignedUserCertification?.actualStartDateUtc === null &&
                    assignedUserCertification?.userId === userId &&
                    selectedCertificationPeriod.id === null &&
                    isInternal &&
                    !getComplianceUsers.isLoading && (
                      <WidthFixedTooltip title="Start certification.">
                        <span>
                          <IconButton
                            onClick={
                              () => setOpenStartCertificationDialog(true)
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                            color="primary"
                            aria-label="Start certification."
                            disabled={disableUI || readOnly}
                          >
                            {businessesQuery.isLoading ||
                            getComplianceUsers.isLoading ? (
                              <Skeleton>
                                <PlayCircleFilledOutlined />
                              </Skeleton>
                            ) : (
                              <PlayCircleFilledOutlined />
                            )}
                          </IconButton>
                        </span>
                      </WidthFixedTooltip>
                    )}

                  {selectedCertificationPeriod.id === null &&
                    isInternal &&
                    selectedBusiness?.type !== SELLER && (
                      <WidthFixedTooltip title="Clone documents">
                        <span>
                          <IconButton
                            onClick={
                              () => setOpenCloneDialog(true)
                              // eslint-disable-next-line react/jsx-curly-newline
                            }
                            color="primary"
                            aria-label="Clone documents"
                            disabled={disableUI || readOnly}
                          >
                            {businessesQuery.isLoading ||
                            getComplianceUsers.isLoading ? (
                              <Skeleton>
                                <FileCopy />
                              </Skeleton>
                            ) : (
                              <FileCopy />
                            )}
                          </IconButton>
                        </span>
                      </WidthFixedTooltip>
                    )}
                </Grid>
              </Box>

              {!isInternal &&
                !categoryQuery.isLoading &&
                userList &&
                userList.length > 1 && (
                  <Box display="flex" justifyContent="flex-end">
                    <Box>
                      <div key="divAssignCategory">
                        <WidthFixedTooltip
                          title={`Assign documents in the ${categorySelected?.name} to an user.`}
                        >
                          <Button
                            ref={anchorRef}
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AssignmentInd fontSize="small" />}
                            onClick={handleClick}
                            disabled={noCategoriesRegistered || !categories}
                          >
                            Assign category to user
                          </Button>
                        </WidthFixedTooltip>
                        <Menu
                          id="assign-category-menu"
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          getContentAnchorEl={null}
                        >
                          {userList &&
                            userList?.map((u: any) => (
                              <MenuItem
                                key={`menuItem ${u.id}`}
                                onClick={() => handleUserSelected(u)}
                              >
                                {u.name}
                              </MenuItem>
                            ))}
                        </Menu>
                      </div>
                    </Box>

                    <Box ml={1}>
                      <div>
                        <Button
                          disabled={noCategoriesRegistered || !categories}
                          style={{
                            minWidth: '100px',
                            justifyContent: 'space-around',
                          }}
                          variant="contained"
                          color="primary"
                          size="small"
                          endIcon={
                            filters.length > 0 && (
                              <Tooltip
                                title={
                                  <Box display="flex" flexDirection="column">
                                    <Box>Documents filtered by: </Box>
                                    {userList &&
                                      filters?.map((u: any) => {
                                        return (
                                          <Box key={`itemFilter${u}`}>
                                            {userList?.find(
                                              (g: any) =>
                                                g.id.toLowerCase() ===
                                                u.toLowerCase()
                                            )?.name || ''}
                                          </Box>
                                        )
                                      })}
                                  </Box>
                                }
                              >
                                <Warning
                                  style={{ color: 'gold' }}
                                  fontSize="small"
                                />
                              </Tooltip>
                            )
                          }
                          startIcon={<FilterList fontSize="small" />}
                          onClick={handleClickFilter}
                        >
                          Filter
                        </Button>

                        <Menu
                          id="filter"
                          anchorEl={anchorFilter}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          keepMounted
                          open={Boolean(anchorFilter)}
                          onClose={handleClose}
                          getContentAnchorEl={null}
                        >
                          <MenuItem>
                            <Box display="flex">
                              <Box>
                                <Tooltip title="Apply filter">
                                  <Button
                                    variant="outlined"
                                    color="inherit"
                                    size="small"
                                    startIcon={
                                      <Check
                                        style={{ color: 'black' }}
                                        fontSize="small"
                                      />
                                    }
                                    onClick={handleApplyFilter}
                                  >
                                    Apply
                                  </Button>
                                </Tooltip>
                              </Box>
                              <Box ml={2}>
                                <Tooltip title="Clear filter">
                                  <Button
                                    variant="outlined"
                                    color="inherit"
                                    size="small"
                                    startIcon={
                                      <Clear
                                        style={{ color: 'red' }}
                                        fontSize="small"
                                      />
                                    }
                                    onClick={handleClearFilter}
                                  >
                                    Clear
                                  </Button>
                                </Tooltip>
                              </Box>
                            </Box>
                          </MenuItem>
                          {userList &&
                            userList?.map((u: any) => (
                              <MenuItem key={u.id} value={u.id}>
                                <Checkbox
                                  onClick={() => handleFilterChange(u.id)}
                                  id={`checkbox_${u.id}`}
                                  checked={inputDataForm.values.filter.includes(
                                    u.id
                                  )}
                                />
                                <ListItemText primary={u.name} />
                              </MenuItem>
                            ))}
                        </Menu>
                      </div>
                    </Box>
                  </Box>
                )}
            </Box>

            {!categoryQuery.isLoading &&
              !businessesQuery.isLoading &&
              availableBusinesses?.length === 0 && (
                <Box display="flex" mt={isInternal ? 5 : 0}>
                  <Typography>No business found for this status.</Typography>
                </Box>
              )}

            <BusinessContext.Provider value={selectedBusiness}>
              <Grid item xs={12}>
                {categories ? (
                  <CategoriesTabs
                    categoryData={categories}
                    origin="registration"
                  />
                ) : (
                  availableBusinesses &&
                  availableBusinesses.length > 0 && (
                    <Skeleton variant="rect" width={1200} height={40} />
                  )
                )}
              </Grid>

              {!noCategoriesRegistered &&
                availableBusinesses &&
                availableBusinesses.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} style={{ marginTop: '20px' }}>
                        {categoryId &&
                        selectedBusiness &&
                        categorySelected &&
                        (userList || isInternal) ? (
                          <DocumentCardMain
                            categoryData={categorySelected}
                            businessUsers={userList}
                            filteredUsers={filters || null}
                            origin="registration"
                            recertification={
                              certificationPeriodsData &&
                              certificationPeriodsData.length > 0
                            }
                            disableItems={readOnly}
                          />
                        ) : (
                          <>
                            <Grid
                              container
                              spacing={9}
                              style={{ marginLeft: '30px' }}
                            >
                              {emptyArray.map((x) => {
                                return (
                                  <Grid item key={x}>
                                    <Skeleton
                                      key={x}
                                      variant="rect"
                                      width={510}
                                      height={250}
                                    />
                                  </Grid>
                                )
                              })}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </BusinessContext.Provider>
          </Grid>
        </Box>
        <Dialog
          open={openCompleteCertificationDialog}
          onClose={() => {
            setOpenCompleteCertificationDialog(false)
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
        >
          <DialogTitleStyled id="form-dialog-title">
            <Typography
              variant="h1"
              style={{ textAlign: 'center', color: 'white' }}
            >
              Complete the Certification Period
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                setReasonStatus('')
                setCertificationStatus('')
                setBusinessStatus('')
                setOpenCompleteCertificationDialog(false)
              }}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Close />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <Box style={{ width: 450, height: 180 }}>
              {completeCertificationMutation.isLoading && (
                <Loader specificHeight={200} />
              )}
              <Grid container style={{ width: '100%' }} spacing={3}>
                <Grid item xs={12} lg={6} md={6}>
                  <TextField
                    data-cy="select-result"
                    id="select-result"
                    fullWidth
                    select
                    label="Result"
                    name="result"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setCertificationStatus(e.target.value)}
                    value={certificationStatus || null}
                  >
                    <MenuItem value="">
                      <em>Select the result</em>
                    </MenuItem>

                    <MenuItem value="Failed" key="Failed">
                      Failed
                    </MenuItem>
                    <MenuItem value="Passed" key="Passed">
                      Passed
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  {certificationStatus === 'Failed' && (
                    <TextField
                      data-cy="select-status"
                      id="select-status"
                      fullWidth
                      select
                      label="Business Status"
                      name="user"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setBusinessStatus(e.target.value)}
                      value={businessStatus || null}
                    >
                      <MenuItem value="">
                        <em>Select a status</em>
                      </MenuItem>

                      <MenuItem value="Suspended" key="Suspended">
                        Suspended
                      </MenuItem>
                      <MenuItem value="Terminated" key="Terminated">
                        Terminated
                      </MenuItem>
                    </TextField>
                  )}
                </Grid>
              </Grid>

              {certificationStatus === 'Failed' && (
                <Box flexDirection="row" mt={3} style={{ width: 450 }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    multiline={true}
                    minRows={4}
                    data-cy="dialog-input-field-reason"
                    label="Reason"
                    name="reason"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      maxLength: 300,
                    }}
                    onChange={(e) => setReasonStatus(e.target.value)}
                    value={reasonStatus || null}
                  />
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setReasonStatus('')
                setCertificationStatus('')
                setBusinessStatus('')
                setOpenCompleteCertificationDialog(false)
              }}
              color="secondary"
              variant="outlined"
              disabled={completeCertificationMutation.isLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCompleteCertificationClick}
              variant="contained"
              color="primary"
              disabled={
                completeCertificationMutation.isLoading ||
                (certificationStatus === 'Passed' &&
                  progressBarPercentValue < 100) ||
                certificationStatus === '' ||
                uploadedProgressBarPercentValue === 0 ||
                (certificationStatus === 'Failed' &&
                  (reasonStatus === '' || businessStatus === ''))
              }
            >
              Complete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAssignCertificationUser}
          onClose={() => {
            setOpenAssignCertificationUser(false)
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
        >
          <DialogTitleStyled id="form-dialog-title">
            <Typography
              variant="h1"
              style={{ textAlign: 'center', color: 'white' }}
            >
              Assign certification to a compliance analyst
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenAssignCertificationUser(false)}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Close />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <AssignCertification
              userId={userId}
              businessId={selectedBusiness?.id}
              assignedUser={assignedUserCertification}
              emailBusiness={selectedBusiness?.emailAddress}
              close={() => handleCloseAssignUser()}
            />
          </DialogContent>
        </Dialog>
        {openCloneDialog && (
          <CloneAllDocuments
            close={() => setOpenCloneDialog(false)}
            businessId={selectedBusiness?.id}
            open={openCloneDialog}
          />
        )}

        <Dialog
          open={openCertificationBusinessComments}
          onClose={() => {
            setOpenCertificationBusinessComments(false)
          }}
          aria-labelledby="form-dialog-title-comment"
          maxWidth="md"
        >
          <DialogTitleStyled id="form-dialog-title-comment">
            <Typography
              variant="h1"
              style={{ textAlign: 'center', color: 'white' }}
            >
              Certification History
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenCertificationBusinessComments(false)}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Close />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <CertificationComment
              businessId={selectedBusiness?.id}
              close={() => handleCloseComments()}
            />
          </DialogContent>
        </Dialog>

        {openAssignCategoryDialog && (
          <ConfirmDialog
            open={openAssignCategoryDialog}
            title="Assign Category"
            loading={assigningCategory}
            description={
              <>
                <Typography variant="body1" color="textSecondary">
                  {`Are you sure you want to assign all documents in this category to ${userSelected.name}?`}
                </Typography>
              </>
            }
            closeName="Cancel"
            confirmName="Continue"
            onClose={handleAssignCategoryClose}
            onConfirm={handleAssignCategory}
          />
        )}

        {openStartCertificationDialog && (
          <ConfirmDialog
            open={openStartCertificationDialog}
            title="Start Certification"
            loading={startCertification.isLoading}
            description={
              <>
                <Typography variant="body1" color="textSecondary">
                  Are you sure you want to start the certification period?
                </Typography>
              </>
            }
            closeName="Cancel"
            confirmName="Continue"
            onClose={() => setOpenStartCertificationDialog(false)}
            onConfirm={handleStartCertification}
          />
        )}
      </CertificationPeriodContext.Provider>
    </>
  )
}

export default DocumentRegistrationMain
