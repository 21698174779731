import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import axios from 'axios'
import { format, isSameDay } from 'date-fns'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { BusinessContext } from 'src/context/BusinessContext'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'
import { stringAvatar } from 'src/utils/common'
import { formatDateAndTimeLocal } from 'src/utils/date'

interface CommentsMainProps {
  documentRequestId: number
  documentData: any
}

const getDateLabel = (date: string) => {
  const commentDate = new Date(date)
  const localDate = new Date()

  if (isSameDay(commentDate, localDate)) {
    return format(commentDate, 'p')
  }

  return formatDateAndTimeLocal(commentDate)
}

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

const CommentsMain: React.FC<CommentsMainProps> = ({
  documentRequestId,
  documentData,
}: CommentsMainProps) => {
  const userToken = GetAccessToken()
  const [commentToAdd, setCommentToAdd] = useState<string>('')
  const queryClient = useQueryClient()
  const certificationPeriodSelected = useContext(CertificationPeriodContext)
  const businessContext = useContext(BusinessContext)

  const queryCommentsData = useQuery({
    enabled: !!documentRequestId,
    queryKey: ['getComments', documentRequestId],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${BACKEND_URL}/businessuploadcomment/${documentRequestId}`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      }),
    refetchInterval: 30000,
  })

  const commentsData = queryCommentsData?.data

  const mutationAddComment = useMutation({
    mutationFn: async () =>
      axios({
        method: 'post',
        url: `${BACKEND_URL}/BusinessUploadComment`,
        data: {
          businessUploadRequestId: documentRequestId || null,
          documentId: documentData.id,
          businessId: businessContext.id,
          businessName: businessContext.name,
          businessTypeName: businessContext.type,
          buyerType: businessContext.buyerType,
          comment: commentToAdd,
        },
        headers: {
          Authorization: userToken,
        },
      }),
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: 'documentRequest',
      })

      await queryClient.refetchQueries({
        queryKey: 'getComments',
      })

      setCommentToAdd('')
    },
  })

  const handleAddComment = () => {
    mutationAddComment.mutate()
  }

  if (certificationPeriodSelected.id !== null && !commentsData) {
    return (
      <Box display="flex" flexDirection="column">
        <Typography>There are no messages</Typography>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box height={330}>
        <List style={{ maxHeight: '100%', overflow: 'auto' }}>
          {queryCommentsData.isLoading && (
            <>
              <Skeleton height={80} width="95%" />
              <Skeleton height={80} width="95%" />
              <Skeleton height={80} width="95%" />
              <Skeleton height={80} width="95%" />
            </>
          )}
          {commentsData?.map((item: any) => {
            return (
              <div key={`div_${item.id}`}>
                <ListItem alignItems="flex-start" key={`item_${item.id}`}>
                  <ListItemAvatar key={`avatar_${item.id}`}>
                    <Avatar {...stringAvatar(item.userName)} />
                  </ListItemAvatar>
                  <ListItemText
                    key={`text_${item.id}`}
                    primary={`${item.userName} - ${getDateLabel(
                      item.creationDateUtc
                    )}`}
                    secondary={item.comment}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            )
          })}
        </List>
      </Box>
      {certificationPeriodSelected.id === null && (
        <Box display="flex" flexDirection="column" mt={3}>
          <Box>
            <TextField
              label="Comment"
              variant="outlined"
              multiline
              minRows={2}
              maxRows={2}
              fullWidth
              disabled={mutationAddComment.isLoading}
              value={commentToAdd}
              onChange={(event) => {
                setCommentToAdd(event.currentTarget.value)
              }}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>Total Number of Comments: {commentsData?.length}</Box>
            <Box display="flex" justifyContent="right" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddComment}
                disabled={mutationAddComment.isLoading || !commentToAdd}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CommentsMain
