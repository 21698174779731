import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import axios from 'axios'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import Loader from 'src/components/Loader'

import SectionCard from 'src/components/SectionCard'

const CertificationManager: React.FC = () => {
  const [option, setOption] = useState('')
  const userToken = GetAccessToken()

  const getCertificationData = useQuery({
    enabled: option !== '',
    queryKey: ['getCertificationManagerCards', option],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/certificationmanager/getCertificationManagerCards/${option}`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const certificationData = getCertificationData?.data || []

  return (
    <>
      <Box padding={4} mt={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">Certification Manager</Typography>
          </Grid>
        </Grid>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={2} md={3}>
              <TextField
                data-cy="select-option"
                id="select-option"
                fullWidth
                select
                label="Option"
                name="result"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setOption(e.target.value)}
                value={option || null}
              >
                <MenuItem value="">
                  <em>Select an option</em>
                </MenuItem>

                {/* <MenuItem value="Analyst" key="Analyst">
                  Analyst
                </MenuItem> */}
                <MenuItem value="Certification" key="Certification">
                  Certification
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <Box mt={10}>
          {getCertificationData.isLoading && <Loader />}
          <Grid container>
            {option !== '' && !getCertificationData.isLoading && (
              <Grid item xs={12} md={12}>
                <SectionCard cards={certificationData || []} />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default CertificationManager
