/* eslint-disable no-unused-vars */
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import axios from 'axios'
import * as React from 'react'
import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { BACKEND_URL } from 'src/api/axios/api'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import hardcodeData from 'src/utils/hardcodeData'
import { GetAccessToken } from 'src/api/axios/helper'
import { Close } from '@material-ui/icons'
import { DialogTitleStyled } from './style'
import { ConfirmDialog } from '../Dialogs'
import MultipleSelectionDropDown from '../MultipleSelectionDropDown'
import Loader from '../Loader'
import DocumentQuestion from '../DocumentQuestion/DocumentQuestion'

interface DocumentCommandCellProps {
  dataItem: any
  category: any[]
  jurisdiction: any[]
  assetTypes: any[]
  confirmCallback: () => void
  updateSuccededCallback: () => void
}

interface IDocument {
  id: number
  categoryId: number | null
  name: string
  aliasName: string
  description: string
  isRequired: boolean
  isEnabled: boolean
  isLicense: boolean
  allowAudioFile: boolean
  businessTypeName: string[]
  validateBuyerTypeName: boolean
  buyerTypeName: string[]
  jurisdiction: number[]
  assetType: number[]
  question: number[]
  includePastDocumentsDueDiligence: boolean
}

const DocumentCommandCell: React.FC<DocumentCommandCellProps> = ({
  dataItem,
  category,
  jurisdiction,
  assetTypes,
  confirmCallback,
  updateSuccededCallback,
}: DocumentCommandCellProps) => {
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [openEditDialog, setOpenEditDialog] = React.useState(false)
  const [assetTypesSelected, setAssetTypesSelected] = useState<any[]>([])
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const userToken = GetAccessToken()
  const [loading, setLoading] = useState(false)
  const [jurisdictionSelected, setJurisdictionSelected] = useState<any[]>([])
  const [questions, setQuestions] = useState<any[]>([])
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false)
  const [questionsSelected, setQuestionsSelected] = useState<any[]>([])

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValues, setInitialValues] = React.useState<IDocument>({
    id: dataItem.id,
    name: dataItem.name,
    description: dataItem.description,
    categoryId: dataItem.categoryId,
    isRequired: dataItem.isRequired,
    aliasName: dataItem.aliasName,
    isEnabled: dataItem.isEnabled,
    allowAudioFile: dataItem.allowAudioFile,
    isLicense: dataItem.isLicense,
    includePastDocumentsDueDiligence: dataItem.includePastDocumentsDueDiligence,
    businessTypeName: dataItem.businessTypeName,
    validateBuyerTypeName: dataItem.businessTypeName.includes('Buyer'),
    buyerTypeName: dataItem.buyerTypeName,
    jurisdiction: dataItem.jurisdiction,
    question: dataItem.question,
    assetType: dataItem.assetType,
  })

  const inputDataFormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    categoryId: Yup.number().typeError('Required').required('Required'),
    isEnabled: Yup.boolean().required('Required'),
    isRequired: Yup.boolean().required('Required'),
    isLicense: Yup.boolean().required('Required'),
    includePastDocumentsDueDiligence: Yup.boolean().required('Required'),
    businessTypeName: Yup.array().min(1, 'Required'),
    validateBuyerTypeName: Yup.boolean(),
    buyerTypeName: Yup.array().when(
      'businessTypeName',
      (arrayData: string[], schema: any) =>
        arrayData.includes('Buyer') ? schema.min(1, 'Required') : schema
    ),
    jurisdiction: Yup.array().when(
      'isLicense',
      (validate: boolean, schema: any) => {
        return validate ? schema.min(1, 'Required') : schema
      }
    ),
  })

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const savedAssetTypes = assetTypes
        .filter((f) => values.assetType.includes(f.id))
        ?.map((c) => {
          return { id: c.id, name: c.name }
        })

      const requestValues = {
        id: values.id,
        name: values.name || null,
        aliasName: values.aliasName || null,
        categoryId: values.categoryId || null,
        description: values.description || null,
        isEnabled: values.isEnabled,
        isRequired: values.isRequired,
        allowAudioFile: values.allowAudioFile,
        isLicense: values.isLicense,
        includePastDocumentsDueDiligence:
          values.includePastDocumentsDueDiligence,
        businessTypeName: values.businessTypeName || null,
        buyerTypeName: values.buyerTypeName || null,
        jurisdiction: values.jurisdiction || null,
        question: values.question || null,
        assetType: savedAssetTypes || null,
      }
      setLoading(true)
      axios({
        method: 'put',
        url: `${BACKEND_URL}/documents`,
        data: requestValues,
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('Data updated', notistackSucces)
          setLoading(false)
          updateSuccededCallback()
        })
        .catch((error) => {
          setLoading(false)
          enqueueSnackbar(error.response.data, notistackError)
        })

      setSubmitting(false)
    },
  })

  const getAvailableAssetTypes = () => {
    const available = assetTypes.map((x) => {
      return {
        id: x.id,
        name: x.name,
        displayName: `${x.name}`,
      }
    })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const renderAssetType = () => {
    const handleChange = (value: any) => {
      setAssetTypesSelected(value)
      inputDataForm.setFieldValue('assetType', value)
    }

    const assetData = assetTypes ? getAvailableAssetTypes() : []

    return (
      <MultipleSelectionDropDown
        id="input-assetType"
        label="Asset Type"
        data={assetData}
        disable={false}
        selectionState={assetTypesSelected}
        handleSelectionChange={handleChange}
        displayProperties={['name']}
      />
    )
  }

  const getAvailableJurisdictions = () => {
    const available = jurisdiction.map((x) => {
      return {
        id: x.id,
        name: x.name,
        type: x.type,
        displayName: `${x.type}: ${x.name}`,
      }
    })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const renderJurisdiction = () => {
    const handleChange = (value: any) => {
      setJurisdictionSelected(value)
      inputDataForm.setFieldValue('jurisdiction', value)
    }

    const data = jurisdiction ? getAvailableJurisdictions() : []

    return (
      <MultipleSelectionDropDown
        id="demo-mutiple-checkbox"
        label="Jurisdiction"
        data={data}
        disable={false}
        selectionState={jurisdictionSelected}
        handleSelectionChange={handleChange}
        separator=":"
        displayProperties={['type', 'name']}
      />
    )
  }

  useEffect(() => {
    if (dataItem && dataItem.assetType) {
      SetDefaultValuesToAssetTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypes, dataItem])

  useEffect(() => {
    if (dataItem && dataItem.jurisdiction) {
      SetDefaultValuesToJurisdiction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jurisdiction, dataItem])

  const SetDefaultValuesToJurisdiction = () => {
    const savedJurisdiction = jurisdiction
      .filter((f) => dataItem.jurisdiction.includes(f.id))
      ?.map((c) => {
        return c.id
      })
    setJurisdictionSelected(savedJurisdiction)
  }

  const SetDefaultValuesToAssetTypes = () => {
    const savedAssetTypes = assetTypes
      .filter((f) => dataItem.assetType.includes(f.id))
      ?.map((c) => {
        return c.id
      })
    setAssetTypesSelected(savedAssetTypes)
  }
  return (
    <>
      <td className="k-command-cell" style={{ paddingLeft: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            data-cy="grid-delete-button"
            onClick={() => {
              setOpenDeleteDialog(true)
            }}
          >
            <Delete />
          </IconButton>
          <IconButton
            data-cy="grid-edit-button"
            onClick={() => {
              setOpenEditDialog(true)
            }}
          >
            <Edit />
          </IconButton>
        </div>
      </td>
      <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false)
          SetDefaultValuesToAssetTypes()
          SetDefaultValuesToJurisdiction()
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitleStyled id="form-dialog-title">
          <Typography
            variant="h1"
            style={{ textAlign: 'center', color: 'white' }}
          >
            {inputDataForm.values.isLicense ? 'License' : 'Document'}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenEditDialog(false)
              SetDefaultValuesToAssetTypes()
              SetDefaultValuesToJurisdiction()
            }}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <form onSubmit={inputDataForm.handleSubmit}>
            {loading && <Loader />}
            <Grid container style={{ height: '100%', width: '100%' }}>
              <Grid
                container
                direction="column"
                style={{ margin: '6px 6px 6px 6px', maxWidth: '200px' }}
                spacing={3}
              >
                <Grid item>
                  <TextField
                    data-cy="dialog-category-dropdown"
                    id="select-request-type"
                    select
                    label="Category"
                    style={{ width: '200px' }}
                    name="categoryId"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={inputDataForm.handleChange}
                    error={!!inputDataForm.errors.categoryId}
                    value={inputDataForm.values.categoryId}
                    helperText={inputDataForm.errors.categoryId}
                  >
                    <MenuItem value="">
                      <em>Select a category</em>
                    </MenuItem>
                    {category.map((dt: any) => {
                      return (
                        <MenuItem key={`${dt.name}`} value={dt.id}>
                          <Typography>{dt.name}</Typography>
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    data-cy="dialog-input-field-name"
                    label="Name"
                    name="name"
                    value={inputDataForm.values.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={inputDataForm.handleChange}
                    error={!!inputDataForm.errors.name}
                    helperText={inputDataForm.errors.name}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    data-cy="dialog-input-field-alias-name"
                    label="Alias Name"
                    name="aliasName"
                    inputProps={{ maxLength: 10 }}
                    value={inputDataForm.values.aliasName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={inputDataForm.handleChange}
                    error={!!inputDataForm.errors.aliasName}
                    helperText={inputDataForm.errors.aliasName}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    data-cy="dialog-input-field-description"
                    label="Description"
                    name="description"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={inputDataForm.values.description}
                    onChange={inputDataForm.handleChange}
                    error={!!inputDataForm.errors.description}
                    helperText={inputDataForm.errors.description}
                  />
                </Grid>
              </Grid>
              <Grid>
                <FormControl>
                  <FormGroup style={{ padding: '12px' }}>
                    <FormLabel
                      data-cy="dialog-business-types-label"
                      component="legend"
                    >
                      Business Types
                    </FormLabel>
                    <FormHelperText style={{ color: 'red' }}>
                      {inputDataForm.errors.businessTypeName}
                    </FormHelperText>

                    {inputDataForm.values.isLicense ? (
                      <RadioGroup
                        data-cy="dialog-radio-group"
                        key="radio-gruop"
                        aria-labelledby="radio-buttons-group"
                        name="radio-buttons-group"
                        value={inputDataForm.values.businessTypeName[0] || ''}
                        onChange={(e) => {
                          inputDataForm.values.businessTypeName.pop()
                          inputDataForm.values.businessTypeName.push(
                            e.target.value
                          )

                          if (e.target.value === 'Buyer')
                            inputDataForm.setFieldValue(
                              'validateBuyerTypeName',
                              !inputDataForm.values.validateBuyerTypeName
                            )

                          inputDataForm.handleChange(e)
                        }}
                      >
                        {hardcodeData.getBusinessType().map((x) => (
                          <FormControlLabel
                            data-cy={`dialog-radio-${x.id}`}
                            key={`radio${x.id}`}
                            value={x.name}
                            control={<Radio />}
                            label={x.name}
                          />
                        ))}
                      </RadioGroup>
                    ) : (
                      hardcodeData.getBusinessType().map((x) => {
                        return (
                          <FormControlLabel
                            data-cy={`dialog-checkbox-${x.id}`}
                            key={`checkbox_${x.id}`}
                            label={x.name}
                            control={
                              <Checkbox
                                name="businessTypeName"
                                onChange={(e) => {
                                  if (e.target.value === 'Buyer')
                                    inputDataForm.setFieldValue(
                                      'validateBuyerTypeName',
                                      !inputDataForm.values
                                        .validateBuyerTypeName
                                    )
                                  inputDataForm.handleChange(e)
                                }}
                                checked={inputDataForm.values.businessTypeName.includes(
                                  x.name
                                )}
                                value={x.name}
                              />
                            }
                          />
                        )
                      })
                    )}
                  </FormGroup>
                </FormControl>
              </Grid>
              {inputDataForm.values.businessTypeName.find(
                (x) => x === 'Buyer'
              ) && (
                <Grid>
                  <FormGroup
                    data-cy="dialog-buyer-type-label"
                    style={{ padding: '12px' }}
                  >
                    <FormLabel component="legend">Buyer Type</FormLabel>
                    <FormHelperText style={{ color: 'red' }}>
                      {inputDataForm.errors.buyerTypeName}
                    </FormHelperText>
                    <FormControlLabel
                      label="Active"
                      control={
                        <Checkbox
                          data-cy="dialog-buyer-type-active-checkbox"
                          name="buyerTypeName"
                          onChange={inputDataForm.handleChange}
                          value="Active"
                          checked={inputDataForm.values.buyerTypeName.includes(
                            'Active'
                          )}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Passive"
                      control={
                        <Checkbox
                          data-cy="dialog-buyer-type-passive-checkbox"
                          name="buyerTypeName"
                          onChange={inputDataForm.handleChange}
                          value="Passive"
                          checked={inputDataForm.values.buyerTypeName.includes(
                            'Passive'
                          )}
                        />
                      }
                    />
                  </FormGroup>
                </Grid>
              )}
              <Grid item style={{ paddingLeft: '20px' }}>
                <Grid item>
                  <Typography component="div">
                    <Grid
                      data-cy="dialog-disable-enabled-selector"
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>Disabled</Grid>
                      <Grid item>
                        <Switch
                          checked={inputDataForm.values.isEnabled}
                          onChange={inputDataForm.handleChange}
                          name="isEnabled"
                        />
                      </Grid>
                      <Grid item>Enabled</Grid>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component="div">
                    <Grid
                      data-cy="dialog-required-not-required-selector"
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>Not Required</Grid>
                      <Grid item>
                        <Switch
                          checked={inputDataForm.values.isRequired}
                          onChange={inputDataForm.handleChange}
                          name="isRequired"
                        />
                      </Grid>
                      <Grid item>Required</Grid>
                    </Grid>
                  </Typography>
                </Grid>
                {(inputDataForm.values.businessTypeName.find(
                  (x) => x === 'Buyer'
                ) ||
                  inputDataForm.values.businessTypeName.find(
                    (x) => x === 'Agency'
                  )) && (
                  <Grid item>
                    <Typography component="div">
                      <Grid
                        data-cy="dialog-allow-audio"
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>Allow Audio File</Grid>
                        <Grid item>
                          <Switch
                            checked={inputDataForm.values.allowAudioFile}
                            onChange={inputDataForm.handleChange}
                            name="allowAudioFile"
                          />
                        </Grid>
                        {inputDataForm.values.allowAudioFile && (
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setQuestionsSelected([])
                                setOpenQuestionDialog(true)
                              }}
                            >
                              Question
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </Typography>
                  </Grid>
                )}
                {inputDataForm.values.isLicense && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          data-cy="dialog-is-license-checkbox"
                          checked={inputDataForm.values.isLicense}
                          onChange={(e) => {
                            if (!inputDataForm.values.isLicense) {
                              inputDataForm.setFieldValue(
                                'businessTypeName',
                                []
                              )
                              inputDataForm.setFieldValue('jurisdiction', [])
                            }

                            inputDataForm.handleChange(e)
                          }}
                          name="isLicense"
                          color="primary"
                        />
                      }
                      label="Is License"
                    />
                  </Grid>
                )}
                {!inputDataForm.values.isLicense && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-cy="dialog-include-past-documents-checkbox"
                          checked={
                            inputDataForm.values
                              .includePastDocumentsDueDiligence
                          }
                          onChange={inputDataForm.handleChange}
                          name="includePastDocumentsDueDiligence"
                          color="primary"
                        />
                      }
                      label="Include past documents to due diligence package"
                    />
                  </Grid>
                )}
                {inputDataForm.values.isLicense && renderJurisdiction()}
                {!inputDataForm.values.isLicense &&
                  inputDataForm.values.businessTypeName.find(
                    (x) => x === 'Seller'
                  ) &&
                  renderAssetType()}
              </Grid>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setOpenEditDialog(false)
                      SetDefaultValuesToAssetTypes()
                      SetDefaultValuesToJurisdiction()
                      inputDataForm.resetForm({ values: initialValues })
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    data-cy="update-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={
                      !inputDataForm.dirty || !inputDataForm.isValid || loading
                    }
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openQuestionDialog}
        onClose={() => {
          setOpenQuestionDialog(false)
          inputDataForm.resetForm({ values: initialValues })
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
      >
        <DialogTitleStyled id="form-dialog-title">
          <Typography
            variant="h1"
            style={{ textAlign: 'center', color: 'white' }}
          >
            Question
          </Typography>

          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenQuestionDialog(false)
              inputDataForm.resetForm({ values: initialValues })
            }}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <DocumentQuestion
            open={openQuestionDialog}
            close={() => setOpenQuestionDialog(false)}
            setQuestionsSelected={(values: any[]) => {
              inputDataForm.setFieldValue('question', values)
              setQuestionsSelected(values)
            }}
            id={dataItem.id}
          />
        </DialogContent>
      </Dialog>
      {openDeleteDialog && (
        <ConfirmDialog
          open={openDeleteDialog}
          title="Delete"
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                {`Would you like to delete this ${
                  inputDataForm.values.isLicense ? 'license' : 'document'
                }?`}
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenDeleteDialog(false)}
          onConfirm={() => {
            confirmCallback()
            setOpenDeleteDialog(false)
          }}
        />
      )}
    </>
  )
}

export default DocumentCommandCell
